<template>
    <div class="head">
        <div class="header">
        <div class="title">
          <el-image  class="icon" :src="require('../../assets/porun.png')" fit="fill" width="100%"></el-image>
            <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
                <span  @click="navigate(item.tab)">{{item.name}}</span>
            </p>
        </div>
        <el-image  style="width: 100%; height: auto;position:absolute;z-index:-1;margin-top:-100px;" :src="require('../../assets/printShop/bg.png')" fit="cover"></el-image>
        </div>
        <!-- <el-button color="#FD8420" class ="experience-btn">立即体验 <div class="box"></div></el-button> -->
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word">
            业务介绍
        </div>
        <div class="word-content">
            <p style="width:70%;margin:0 auto;">
                流海改造传统文印店，打造出集自助收银、扫码打印、用户自助服务、引流增收为一体的智能文印店。 
                <br/><span style="margin-top:50px;display:inline-block"></span>用户进入门店后，打开手机，扫描店内的二维码，手机上传文件，设置打印参数，支付后一键操作打印，省时省力。无需登录微信或者添加微信好友进行传文件，解决登录微信慢，传文件慢，容易泄露微信隐私的问题。
                <br/><span style="margin-top:50px;display:inline-block"></span>用户使用电脑操作打印也可一键调出收银台，打印价格透明，自助收银，支付后出纸，节约人力成本的同时，又保障了资金的安全。文印店主可自定义打印价格，设置打印机，所有经营的订单可查，收益账目清晰。
                <br/><span style="margin-top:50px;display:inline-block"></span>流海智能文印店真正做了无人看店、自助打印、店铺经营效率高、用户服务体验好！
            </p>
        </div>
      
        <div class="word">
            投放场景
        </div>
        <div class="word" style="margin-top: 50px;margin-bottom:80px;font-size:20px;color:#666666">
            高校、社区经过改造的智能文印店
        </div>
       
        <el-carousel :interval="4000" type="card" height="450px" style="min-width:1200px;">
          <el-carousel-item v-for="(item,index) in data.printImg" :key="index">
            <el-image  style="width:600px;height:450px" :src="item.url" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
       
        <div class = "word">
            <p>特色功能</p>
        </div>
        <div style="display:flex;min-width:1200px;text-align:center">
          <div class ="pc">
            <el-image  style="width: 600px; height: auto;margin:0 auto;" lazy :src="require('../../assets/printShop/pc.png')" fit="fill"></el-image>  
          </div>

          <div class ="computer">
              <span class ="computer-title" style="margin-left:-220px;">手机打印</span>
              <span class ="computer-con">手机扫码上传文件，稳定、快速，可多人扫码打印，提高经营效率</span>
              <br/>
              <span class ="computer-title" style="margin-left:-70px">电脑打印</span>
              <span class ="computer-con">扫码传文件>打印>收款>出纸，自助收银，节省人力成本</span>
              <br/>
              <span class ="computer-title" style="margin-left:-30px">流海联盟</span>
              <span class ="computer-con">免做账目，N+1提现，订单、耗材、营收、清晰可查</span>
              <br/>
              <span class ="computer-title" style="margin-top:40px;margin-left:-30px">增值服务</span>
              <span class ="computer-con">论文查重、论文排版、校园文库等额外服务增加粘性</span>
              <br/>
              <span class ="computer-title" style="margin-top:35px;margin-left:-100px">信息安全</span>
              <span class ="computer-con">用户独立账户，文件打印7天后销毁，保障文档安全</span>
              <br/>
              <span class ="computer-title" style="margin-left:-180px;">拉新活动</span>
              <span class ="computer-con">不定期鱼籽充值、商户合作免单等拉新活动，助力引流增收</span>
          </div>  
        </div>
        <div class="word">
            产品介绍
        </div> 
        
        <div class = "content-right" style="margin-top:300px;">
          <div style="width:50%;min-width:600px;">
            <p class = "school-main-right">
              <el-image  style="width: 30px; height: 30px;" lazy :src="require('../../assets/printShop/computer.png')" fit="fill"></el-image>
              <span class="blod-phone" style="margin-left:20px;">电脑自助打印<br/></span>
              <span class="blod-phone">软件自动计算纸张数量，先支付后打印，防止纸张损耗， 可自动识别Word、Excel、PPT图片等多种文件格式并单独计价<br/></span>
            </p>
            <div style="margin-left:300px;margin-top:-400px;">
              <p class = "school-main-right">
                <el-image  style="width: 30px; height: 30px;" lazy :src="require('../../assets/printShop/phone.png')" fit="fill"></el-image>
                <span class="blod-phone" style="margin-left:20px;">手机自助打印<br/></span>
                <span class="blod-phone">手机扫码操作，降低电脑占用率，减少高峰期排队客户的流失， 同等的经营时间可服务更多客户，增加营收。<br/></span>
              </p>
            </div>
            <div style="margin-left:300px;margin-top:30px;">
              <p class = "school-main-right">
                <el-image  style="width: 30px; height: 30px;" lazy :src="require('../../assets/printShop/appoint.png')" fit="fill"></el-image>
                <span class="blod-phone" style="margin-left:20px;">预约打印<br/></span>
                <span class="blod-phone">提高取件效率，用户手机下单，商户网上接单，线下打印， 支持自取或配送设置。<br/></span>
              </p>
            </div>
          </div>
          <div style="margin-left:600px;margin-top:-500px;width:50%;min-width:600px;">
              <p style="font-size: 40px;font-family: Adobe Heiti Std;font-weight: bold;color: #FF8A79;line-height: 50px;" class ="">PRINT</p>
              <p style="width: 200px;font-size: 50px;font-family: Adobe Heiti Std;font-weight: bold;color: #262626;line-height: 91px;margin-left:100px;">打印功能</p>
              <div style="background: linear-gradient(-90deg, #FFA76A 0%, #FF897A 100%);width: 110px;height: 7px;margin-left:100px;margin-bottom:30px;"></div>
              <el-image  style="width: 450px; height: 300px;" lazy :src="require('../../assets/printShop/printshop.png')" fit="cover"></el-image>  
          </div>
        </div>
            
        <div style="width: 1200px;height: 1px;background: #CCCCCC;border-radius: 50%;margin:0 auto;margin-top:100px;"></div>
      
        <div class = "content">
            <div style="width:50%;min-width:600px;">
                <p style="font-size: 40px;font-family: Adobe Heiti Std;font-weight: bold;color: #FF8A79;line-height: 50px;margin-left:150px;" class ="">VALUE</p>
                <p style="width: 200px;font-size: 50px;font-family: Adobe Heiti Std;font-weight: bold;color: #262626;line-height: 91px;">专业赋能</p>
                <div style="background: linear-gradient(-90deg, #FFA76A 0%, #FF897A 100%);width: 110px;height: 7px;margin-bottom:30px;"></div>
                <el-image  style="width: 450px; height: 300px;" lazy :src="require('../../assets/printShop/printshop.png')" fit="fill"></el-image>  
            </div>
            <div style="width:50%;min-width:600px;">
              <div style="margin-top:-450px;margin-left:600px;">
                <p class = "school-main-right">
                  <el-image  style="width: 30px; height: 30px" lazy :src="require('../../assets/printShop/film.png')" fit="fill"></el-image>
                  <span class="blod-phone" style="margin-left:20px;">快速上传文件<br/></span>
                  <span class="blod-phone" style="line-height: 30px;">免登陆上传微信、网盘、手机本地文件, 减免客户登陆微信的时间，减少电脑占用。<br/></span>
                </p>
              </div>
              <div style="margin-left:600px;margin-top:30px;">
                <p class = "school-main-right">
                  <el-image  style="width: 30px; height: 30px" lazy :src="require('../../assets/printShop/sale.png')" fit="fill"></el-image>
                  <span class="blod-phone" style="margin-left:20px;">流海联盟<br/></span>
                  <span class="blod-phone" style="line-height: 30px;">云端实时监控设备信息，成熟完善的运营团队提供即时有效的售后跟踪服务，保障文印店良好运营。<br/></span>
                </p>
              </div>
              <div style="margin-left:900px;margin-top:-350px;">
                <p class = "school-main-right">
                  <el-image  style="width: 30px; height: 30px;" lazy :src="require('../../assets/printShop/logo.png')" fit="fill"></el-image>
                  <span class="blod-phone" style="margin-left:20px;">售后运营<br/></span>
                  <span class="blod-phone" style="line-height: 30px;"><br/>商户端后台管理系统 智能管理商铺，订单、耗材、营收、库存实时可查，免做账目，数据环比清晰明了。</span>
                </p>
              </div>
            </div>
        </div>
         
        <div style="width: 1200px;height: 1px;background: #CCCCCC;border-radius: 50%;margin:0 auto;margin-top:250px;"></div>
       
        <div class = "content-right" style="margin-top:300px;">
          <div style="width:50%;min-width:600px;">
            <p class = "school-main-right">
              <el-image  style="width: 30px; height: 30px" lazy :src="require('../../assets/printShop/chack.png')" fit="fill"></el-image>
              <span class="blod-phone" style="margin-left:30px;">论文查重<br/></span>
              <span class="blod-phone">流海论文查重背靠维普、paperdd数据库、10分钟出结果，支持下载、打印、原文对比，精准、快速、性价比更高！<br/></span>
            </p>
            <div style="margin-left:300px;margin-top:-430px;">
              <p class = "school-main-right">
                <el-image  style="width: 30px; height: 30px;" lazy :src="require('../../assets/printShop/photo.png')" fit="fill"></el-image>
                <span class="blod-phone" style="margin-left:20px;">智能证件照<br/></span>
                <span class="blod-phone">满足多种场景各种尺寸的证件照抠图换背景、自动裁剪、仅下载、支持冲印…自己动手，制作满意的证件照。<br/></span>
              </p>
            </div>
            <div style="margin-left:300px;margin-top:30px;">
              <p class = "school-main-right">
                <el-image  style="width: 30px; height: 30px" lazy :src="require('../../assets/printShop/首单立减.png')" fit="fill"></el-image>
                <span class="blod-phone" style="margin-left:20px;">随机立减<br/></span>
                <span class="blod-phone">流海与支付宝、招商银行等联合推出支付随机立减的活动，每单0.1~3元补贴用户，最高免单！增加用户粘性。<br/></span>
              </p>
            </div>
          </div>
            <div style="margin-left:600px;margin-top:-500px;">
                <p style="font-size: 40px;font-family: Adobe Heiti Std;font-weight: bold;color: #FF8A79;line-height: 50px;" class ="">INCOME</p>
                <p style="width: 200px;font-size: 50px;font-family: Adobe Heiti Std;font-weight: bold;color: #262626;line-height: 91px;margin-left:100px;">引流增收</p>
                <div style="background: linear-gradient(-90deg, #FFA76A 0%, #FF897A 100%);width: 110px;height: 7px;margin-left:100px;margin-bottom:30px;"></div>
                <el-image  style="width: 450px; height: 300px;" lazy :src="require('../../assets/printShop/printshop.png')" fit="fill"></el-image>  
            </div>
        </div>
        <div class="word">
            后续服务
        </div>
        <div class = "operational-standards">
            <div class = "nomal" v-for ="(item,index) in data.products" :key = "index">
                <el-image  style="width:130px;height:130px" :src="item.url" fit="fill"></el-image>
                <p class ="nomal-con">{{item.title}}</p>
                <br/>
                <div class ="nomal-title">
                  {{item.content}}
                  <br/>{{item.content1}}
                </div>
            </div>
        </div>
        <foo></foo>
        <suspension></suspension>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter} from "vue-router";
import foo from '@/views/print/components/foo.vue'
import swiper from '@/views/print/components/swiper.vue'
import suspension from '@/views/print/components/suspension.vue'
export default defineComponent({
    components:{
      foo,
      swiper,
      suspension
    },
    metaInfo: {
      title:'流海云印——智能打印',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、引流增收、提高效率、自助收银、节省人力成本、提供自助打印解决方案'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
       const router = useRouter(); 
        const data = ref({
        titles:[
          {
            name:'首页',
            tab:'/',
          },
          {
            name:'高校打印',
            tab:'/print',
          },
          {
            name:'便利打印',
            tab:'/convenience',
          },
          {
            name:'智能文印店',
            tab:'/intelligentPrintShop',
          },
          {
            name:'关于我们',
            tab:'/porun',
          }
        ],
        Array:[
            {
                img:require('../../assets/convenience/bg.png'),
            },
            {
                img:require('../../assets/school/bg.png'),
            },
            {
                img:require('../../assets/bg.png'),
            },
        ],
        products:[
          {
            content:'1对1安装培训指导',
            url:require('../../assets/printShop/1.png'),
            title:'安装服务',
          },
          {
            content:'7*12小时即时服务',
            url:require('../../assets/printShop/2.png'),
            title:'专属客服',
          },
          {
            content:'商家合作沙龙，交流运营经验',
            url:require('../../assets/printShop/3.png'),
            title:'行业沟通',
          },
          {
            content:'专业拉新引流方案服务',
            url:require('../../assets/printShop/4.png'),
            title:'经营方案',
          },
        ],
        printImg:[
          {
            url:require('../../assets/printShop/con1.jpg'),
          },
          {
            url:require('../../assets/printShop/con2.jpg'),
          },
          {
            url:require('../../assets/printShop/con3.jpg'),
          },
        ]
      })
      const navigate = (url) => {
        router.push(url)
      };
      onMounted(()=>{
            window.scrollTo(0,0);
        })
        return {
            data,
            navigate,
        }
    }
})
</script>

<style scoped>
.header{
  width: 100%;
  position: relative;
  min-width: 1200px;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
}
@media screen and (max-width: 1200px) {
    .video {
        display: none;
    }
}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;
  opacity: 1;
}
.title{
  margin:0 auto;
  width: 1200px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #FFFFFF;
}
.item:hover{
  font-size:21px;
  color: #FFFFFF;
  opacity:0.8;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
  overflow: hidden;
  width: 1200px;
}
.word{
  min-width:1200px;
  margin: 0 auto;
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:150px;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
.experience-btn{
  margin-top:25%;
  margin-left:30%;
  width:250px;
  height:80px;
  color: white;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
}
.content{
  width: 1200px;
  margin: 100px auto;
}
.school-main{
  width: 600px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding:50px 0 0 0;
  padding-left:1150px;
}
.blod{
  font-size: 18px;
  font-weight: bold;
}
.blod-phone{
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-top:5px;
  line-height:30px;
}
.content-right{
  width: 1200px;
  text-align: center;
  margin:100px auto;
}
.school-main-right{
  width: 250px;
  height:200px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #FFFFFF;
  background: linear-gradient(263.28999999999996deg, #FF887B 0%, #FFA969 100%);
  border-radius: 23px 23px 23px 23px;
  padding:18px;
}
.word-content{
    min-width: 1200px;
    font-size: 23px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
    line-height:30px;
}
.nomal{
  width: 300px;
  text-align:center;
  margin-left:70px;
}
.nomal-title{
  font-size: 22px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
  line-height: 36px;
}
.nomal-con{
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 35px;
  font-weight: normal;
  color: #333333;
  text-align:center;
  margin: 20px 20px 10px 20px;
}
.operational-standards{
  display:flex;
  justify-content:center;
  margin-top:100px;
  min-width:1200px;
}
.user{
    font-size: 40px;
    font-family: SimHei;
    font-weight: 400;
    color: #FD8420;
    margin-top:-80px;
    margin-left:250px;
}
.swiper{
    margin: 0 auto;
}
.pc{
    width: 50%;
    min-width:600px;
    margin: 100px auto;
}
.computer{
    min-width: 600px;;
    width: 50%;
    margin:100px auto;
}
.computer-title{
    display: inline-block;
    font-size: 22px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
    color: #333333;
    margin-bottom:50px;
}
.computer-con{
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #666666;
}
.el-carousel__item{
  line-height: 450px;
  margin: 0;
  text-align: center;
}
</style>