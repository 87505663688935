<template>
    <div class="head">
      <div class="header">
        <div class="title">
          <el-image  class="icon" :src="require('../assets/porun.png')" fit="fill" width="100%"></el-image>
            <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
              <span  @click="navigate(item.tab)">{{item.name}}</span>
            </p>
        </div>
        <el-image  style="width: 100%; height: auto;position:absolute;z-index:-1;margin-top:-100px;" :src="require('../assets/bg.png')" fit="fill"></el-image>
      </div>
        <div style="min-width:1200px;margin:0 auto">
          <el-button color="#FFFFFF" @click="toBottom" class ="experience-btn">立即体验 <div class="box"></div></el-button>
        </div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word">
          云打印领跑者
        </div>
        <div class = "main">
          <div v-for ="(i,index) in data.info" :key="index">
            <el-image :src="i.url"  fit="fill" class="img"></el-image>
            <div style="font-size: 40px;font-family: SimHei;font-weight: 400;color: #333333;">{{i.num}}</div>
            <div style="font-size: 23px;font-family: SimHei;font-weight: 400;color: #666666;">{{i.name}}</div>
          </div>
        </div>
      
        <div class="word">
          产品介绍
        </div>
        
        <div class = "product">
          <el-radio-group v-model="data.radio" fill="#FD8420" color="#FD8420" @change="getHeight">
            <el-radio-button label="高校打印"
                             size="large"
                             style="border-left: 1px solid #FD8420;
                                    border-right: 0px solid #FD8420;
                                    border-top:1px solid #FD8420;
                                    border-bottom:1px solid #FD8420;">
            </el-radio-button>
            <el-radio-button  label="便利打印"
                              size="large"
                              style="border-right: 1px solid #FD8420;
                                     border-left: 1px solid #FD8420;
                                     border-top:1px solid #FD8420;
                                     border-bottom:1px solid #FD8420;">
            </el-radio-button>
            <el-radio-button  label="智能文印店"
                              size="large"
                              style="border-right: 1px solid #FD8420;
                                     border-left: 0px solid #FD8420;
                                     border-top:1px solid #FD8420;
                                     border-bottom:1px solid #FD8420;" ></el-radio-button>
          </el-radio-group>
        </div>
        
        <div class = "content">
          <el-image  style="width: 47.5%;min-width:600px; height: auto;float:left;margin-left:2.5%" lazy :src="require('../assets/print.png')" fit="cover"></el-image>
          <div class = "school-main">
            <p class="school-title">
              高校打印
            </p>
            流海云印打印自助终端投放在校园内的<span class="blod">图书馆、教学楼、寝室楼</span>等位置,分散式网状布局，满足各种场景下的打印需求。再也不怕打印店人多，排队等候啦！
          </div>
        </div>
        <div class = "content-right">
          <p class = "school-main-right">
            <div class="school-title-right">
              便利打印
            </div>
              任何场所，一台机器，实现自助打印！用自助服务实现使用自由！
          </p>
          <el-image  style="width:47.5%;min-width:600px; height: auto;float:right" lazy :src="require('../assets/convenientPrint.jpg')" fit="cover"></el-image>
        </div>
        <div class = "content" style="margin-top:0px">
          <el-image  style="width:47.5%;min-width:600px; height:auto;float:left;margin-left:2.5%;" lazy :src="require('../assets/convenientPrint1.jpg')" fit="cover"></el-image>
          <div class = "school-main">
            <p class="school-title">
              智能文印店
            </p>
            文印店自助打印解决方案，自助打印领跑者！ 节省人力成本，助力引流增收
            <br/>
            用自助打印提升您的经营效率
          </div>
        </div>
        
        <div class="word">
          品牌合作伙伴
        </div>
        
        <div class="cooperation">
          <div v-for ="(i,index) in data.imgs" :key="index" style="margin: 20px 50px;">
              <el-image  style="width: 120px; height: 120px;" :src="i.url" fit="fill"></el-image>
          </div>
        </div>
 
        <div class="map">
          <div style="min-width: 30%;height: auto;background: linear-gradient(-90deg, #FA9643, #6E77BA);border-radius: 20px;"></div>
          <div style="opacity: 0;width:2%;">占个位</div>
          <div style="min-width: 30%;height: auto;background: #FA9643;border-radius: 20px;padding:0 20px;">
            <p class="newhight-map">流海分布图</p>
            <el-image  style="width: 100%; height: auto;" :src="require('../assets/map.png')" fit="cover"></el-image>
          </div>
          <div style="opacity: 0;width:2%;">占个位</div>
          <div style="min-width: 30%;height: auto;background: linear-gradient(90deg, #FA9643, #6E77BA);border-radius: 20px;"></div>
        </div>

        <div class="word">
          部分合作高校
        </div>

        <div class="school">
          <div v-for ="(i,index) in data.school" :key = "index">
             <el-image  style="width: 180px; height: 45px;margin-left:50px;margin-top:50px;" :src="i.url" fit="fill"></el-image>
          </div>
        </div>

        <div class="word-omit">
          ……
        </div>

        <div class="join-us">
          <div class= "join">
            <p>加入我们</p>
            <p class="join-main">客户省心！
              用户舒心！
              您放心!
            </p>
            <P class="join-content">
              <span class="join-title">
                流海云印
              </span>
              既是多种刚需场景下的线上、线下集客工具，也是对传统零售文印门店经营和消费模式的一次革新性的产业互联网优化及服务体验升级。</P>
            <div class="join-foot">
              <el-image  style="width:15px;height:15px;margin-top:20px;" :src="require('../assets/call.png')" fit="fill"></el-image>
              <span>       400-8785799</span>
            </div>
          </div>

          <div class="join-img">
            <p style="position:absolute;z-index:999;width: 60px;font-size: 25px;font-family: SimHei;font-weight: 400;color: #FFFFFF;text-align:center;margin:220px 0 0 -40px">
              <span style="font-size:35px;font-weight:bold">8+</span><br/>年<br/>经验
            </P>
            <el-image  style="width: 180px; height: 180px;float:left;margin:200px 0 0 -100px ;" :src="require('../assets/bg-4.png')" fit="fill"></el-image>
            <div class="join-img-three">
              <p style="position:absolute;z-index:999;width: 60px;font-size: 25px;font-family: SimHei;font-weight: 400;color: #FFFFFF;text-align:center;margin:140px 0 0 340px">
                <span style="font-size:35px;font-weight:bold">22+</span><br/>省市
              </P>
              <el-image  style="width: 100px; height: 100px;margin:60px 0 0 80px;" :src="require('../assets/logo.png')" fit="fill"></el-image>
              <p style="position:absolute;z-index:999;width: 65px;font-size: 21px;font-family: SimHei;font-weight: 400;color: #FFFFFF;text-align:center;margin:150px 0 0 -65px">
                <span style="font-size:35px;font-weight:bold">100+</span><br/>加盟商
              </P>
              <el-image  style="width: 130px; height: 50px;margin:0px 0 0 60px;" :src="require('../assets/logo-orange.png')" fit="fill"></el-image>
            </div>
            <el-image  style="width: 180px; height: 180px;margin:-200px 0 0 400px;" :src="require('../assets/bg-3.png')" fit="fill"></el-image>
            <el-image  style="width: 180px; height: 180px;" :src="require('../assets/bg-2.png')" fit="fill"></el-image>
              <p style="position:absolute;z-index:999;width: 65px;font-size: 21px;font-family: SimHei;font-weight: 400;color: #FFFFFF;text-align:center;margin:-150px 0 0 280px">
                <span style="font-size:35px;font-weight:bold">2000+</span><br/>终端
              </P>
            <el-image  style="width: 180px; height: 180px;margin-left:50px;" :src="require('../assets/bg-1.png')" fit="fill"></el-image>
          </div>
        </div>
        
        <foo></foo>
        <suspension></suspension>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter} from "vue-router";
import suspension from '@/views/print/components/suspension.vue'
import foo from '@/views/print/components/foo.vue'

export default defineComponent({
    components:{
      foo,
      suspension,
    },
    metaInfo: {
      title:'流海云印',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、让文印更智能、随时打印、随时取件、24H*7服务。'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
      const router = useRouter(); 
      const data = ref({
        titles:[
          {
            name:'首页',
            tab:'/',
          },
          {
            name:'高校打印',
            tab:'/print',
          },
          {
            name:'便利打印',
            tab:'/convenience',
          },
          {
            name:'智能文印店',
            tab:'/intelligentPrintShop',
          },
          {
            name:'关于我们',
            tab:'/porun',
          }
        ],
        info:[
          {
            name: '投放高校',
            num: "200+",
            url: require('../assets/school.png'),
          },
          {
            name: '社区文印店',
            num: '100+',
            url: require('../assets/print-shop.png'),
          },
          {
            name: '流海会员',
            num: '800万',
            url: require('../assets/people.png'),
          },
          {
            name: '云打印订单',
            num: '5.2亿',
            url: require('../assets/paper.png'),
          },
          {
            name: '打印纸张',
            num: '20.3亿',
            url: require('../assets/paper.png'),
          }
        ],
        imgs:[
          {
            url:require('../assets/1.png'),
          },
          {
            url:require('../assets/2.png'),
          },
          {
            url:require('../assets/3.png'),
          },
          {
            url:require('../assets/4.png'),
          },
          {
            url:require('../assets/5.png'),
          },
          {
            url:require('../assets/18.png'),
          },
          {
            url:require('../assets/6.png'),
          },
          {
            url:require('../assets/7.png'),
          },
          {
            url:require('../assets/8.png'),
          },
          {
            url:require('../assets/9.png'),
          },
          {
            url:require('../assets/10.png'),
          },
          {
            url:require('../assets/17.png'),
          },
          {
            url:require('../assets/11.png'),
          },
          {
            url:require('../assets/12.png'),
          },
          {
            url:require('../assets/13.png'),
          },
          {
            url:require('../assets/14.png'),
          },
          {
            url:require('../assets/15.png'),
          },
          {
            url:require('../assets/16.png'),
          },
        ],
        school:[
           {
            url:require('../assets/清华大学.png'),
          },
          {
            url:require('../assets/北京大学.png'),
          },
          {
            url:require('../assets/长安大学.png'),
          },
          {
            url:require('../assets/中央财经大学.png'),
          },
          {
            url:require('../assets/中国农业大学.png'),
          },
          {
            url:require('../assets/中国地质大学.png'),
          },
          {
            url:require('../assets/湖北大学.png'),
          },
          {
            url:require('../assets/江苏大学.png'),
          },
          {
            url:require('../assets/山东大学.png'),
          },
          {
            url:require('../assets/四川外国语大学.png'),
          },
          {
            url:require('../assets/重庆理工大学.png'),
          },
          {
            url:require('../assets/电子科技大学.png'),
          },
          {
            url:require('../assets/温州大学.png'),
          },
          {
            url:require('../assets/南昌大学.png'),
          },
          {
            url:require('../assets/成都大学.png'),
          },
          {
            url:require('../assets/重庆交通大学.png'),
          },
          {
            url:require('../assets/重庆大学.png'),
          },
          {
            url:require('../assets/武汉科技大学.png'),
          },
          {
            url:require('../assets/兰州大学.png'),
          },
          {
            url:require('../assets/武汉大学.png'),
          },
          {
            url:require('../assets/扬州大学.png'),
          },
          {
            url:require('../assets/西藏大学.png'),
          },
          {
            url:require('../assets/陕西师范大学.png'),
          },
          {
            url:require('../assets/桂林理工.png'),
          },
        ],
        radio:'高校打印',
        scroll:'',
      })
      const navigate = (url) => {
        router.push(url)
      };
      const getHeight = (e)=> {
        // data.value.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        if(e =='高校打印'){
          document.documentElement.scrollTop = 1350;
        }else if(e =='便利打印'){
          document.documentElement.scrollTop = 2150;
        }else if(e =='智能文印店'){
          document.documentElement.scrollTop = 2750;
        }
      }
      const toBottom = ()=>{
        window.scrollTo(0,document.body.scrollHeight);
      }
      onMounted(()=>{
            window.scrollTo(0,0);
      })
      return {
        data,
        navigate,
        getHeight,
        toBottom,
      }
    },
  
    
})
</script>

<style scoped>
.header{
    width: 100%;
    position: relative;
	  min-width: 1200px;
    height: auto;
	  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
    .school-main{
    }
}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;

}
.title{
  margin:0 auto;
  width: 1200px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #FFFFFF;
}
.item:hover{
  font-size:21px;
  color: #FFFFFF;
  opacity:0.8;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
  overflow: hidden;
  width: 1200px;
}
.word{
  min-width:1200px;
  margin: 0 auto;
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:150px;
}
.product{
  text-align: center;
  margin: 100px auto;
  min-width: 1200px;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
.content{
  width:95%;
  min-width:1200px;
  margin-top:150px;
  height:auto;
  margin:0 auto;
}
.school-title{
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  white-space:nowrap;
  margin:80px auto;
}
.school-main{
  width: 30%;
  min-width:360px;
  font-size: 23px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  float:right;
  margin-right:200px;
}
.blod{
  color:#FD8420;
  font-size: 25px;
}
.content-right{
  min-width:1200px;
  width:95%;
  height:auto;
  /* margin-top:200px; */
}
.content-right:after,.content-right:before{
  content: "";
  display: block;
  clear: both;
}
.content:after,.content:before{
  content: "";
  display: block;
  clear: both;
}

.school-title-right{
  width:50%;
  min-width:600px;
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin:100px auto;
  text-align:center;
}
.school-main-right{
  width: 40%;
  min-width:480px;
  font-size: 23px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  float:left;
  padding-left:5%;
  text-align:center;
}
.cooperation{
  width:80%;
  display:flex;
  justify-content: center;
  min-width:1200px;
  flex-wrap: wrap;
  margin:100px auto;
  /* padding-left:5%; */
  
}
.map{
  width:95%;
  margin:100px auto;
  display:flex;
  min-width:1200px;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow:hidden;
}
.newhight-map{
  text-align:center;
  font-size: 40px;
  margin-top:80px;
  font-family: SimHei;
  font-weight: 400;
  color: #FFFFFF;
}
.school{
  width:80%;
  min-width:1200px;
  display:flex;
  justify-content: start;
  flex-wrap: wrap;
  margin:50px auto;
}
.word-omit{
  width:100%;
  min-width:1200px;
  text-align:center;
  font-size: 40px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin:100px auto;
}
.join-us{
  width:80%;
  min-width:1200px;
  display:flex;
  justify-content:center;
  margin:0 auto;
}
.join{
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #FD8420;
  height:auto;
  min-width:400px;
  width:45%;
  /* margin: 0 auto; */
}
.join-main{
  width: 30%;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
  margin: 50px 0 0 150px;
}
.join-title{
  font-size: 25px;
  font-family: SimHei;
  font-weight: 400;
  color: #FD8420;
}
.join-content{
  width: 70%;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  margin-top:70px;
}
.join-foot{
  width: 200px;
  height: 50px;
  color:#FFFFFF;
  background: #FD8420;
  border-radius: 30px;
  line-height:50px;
  text-align:center;
  margin-top:50px;
}
.join-img{
  width: 500px;
  height: 500px;
  background: rgba(255, 255, 255, 0);
  border: 3px solid rgba(226, 226, 226, 0.27);
  border-radius: 50%;
}
.join-img-three{
  width: 250px;
  height: 250px;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #D8EDF4;
  box-shadow: 0px 5px 50px 0px rgba(63, 159, 197, 0.2);
  border-radius: 50%;
  margin:120px 0 0 120px;
}
.experience-btn{
  margin-top:25%;
  margin-left:10%;
  width:250px;
  height:80px;
  color: #FE7B0F;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
}
.box{
  border-style: solid;
  border-width: 15px 15px 15px 20px;
  border-color: transparent transparent transparent #FE7B0F;
  width: 0px;
  height: 0px;
  margin-left:10px;
}
</style>