<template>
    <div class="head">
        <div class="header" v-if ="data.isShow">
            <div class="title" v-show="data.head">
                <el-image  class="icon" :src="require('../../assets/porun/logo.png')" fit="fill"></el-image>
                <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
                    <span  @click="navigate(item.tab)">{{item.name}}</span>
                </p>
            </div>
        </div>
        <div class="word" style="padding-top:50px;margin-top:90px;">
            <div class = "product">
                <el-radio-group v-model="data.radio" fill="#FD8420" color="#FD8420" @change="getValue">
                    <el-radio-button label="高校打印"
                                    size="large"
                                    style="border-left: 1px solid #FD8420;
                                            border-right: 0px solid #FD8420;
                                            border-top:1px solid #FD8420;
                                            border-bottom:1px solid #FD8420;">
                    </el-radio-button>
                    <el-radio-button  label="智能文印店"
                                    size="large"
                                    style="border-right: 1px solid #FD8420;
                                            border-left: 0px solid #FD8420;
                                            border-top:1px solid #FD8420;
                                            border-bottom:1px solid #FD8420;" >
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div v-if="data.show">
          <div class ="problem" v-for="(item,index) in data.problems" :key="index">
            <div class ="" style="padding:20px;">
                 <el-image lazy style="width:30px;height:30px" :src="require('../../assets/porun/question.png')" fit="fill"></el-image>
                 <span class ="pro-title">{{item.title}}</span>
                 <br/>
                 <span class= "pro-con">
                   <span style="font-size: 22px;font-family: SimHei;font-weight: blod;color:#333333">解答：</span>{{item.content}}
                  <br/>
                   {{item.content1}}
                   <br  v-if="item.content2"/>
                   {{item.content2}}
                   <br v-if="item.content3"/>
                   {{item.content3}}
                 </span>
            </div>
          </div>
        </div>

        <div v-if="!data.show">
          <div class ="problem" v-for="(item,index) in data.print_shop_problems" :key="index">
            <div class ="" style="padding:20px;">
                 <el-image lazy style="width:30px;height:30px" :src="require('../../assets/porun/question.png')" fit="fill"></el-image>
                 <span class ="pro-title">{{item.title}}</span>
                 <br/>
                 <span class= "pro-con">
                   <span style="font-size: 22px;font-family: SimHei;font-weight: blod;color:#333333">解答：</span>{{item.content}}
                  <br/>
                   {{item.content1}}
                   <br v-if="item.content2"/>
                   {{item.content2}}
                   <br v-if="item.content3"/>
                   {{item.content3}}
                 </span>
            </div>
          </div>
        </div>
        <suspension></suspension>
        <foo></foo>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted,watch } from 'vue'
import  {useRouter} from "vue-router";
import foo from '@/views/print/components/foo.vue'
import suspension from '@/views/print/components/suspension.vue'
export default defineComponent({
    components:{
      foo,
      suspension,
    },
    metaInfo: {
      title:'流海云印——常见问题',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、让文印更智能、随时打印、随时取件、24H*7服务。'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
       const router = useRouter(); 
        const data = ref({
        titles:[
          {
            name:'首页',
            tab:'/',
          },
          {
            name:'高校打印',
            tab:'/print',
          },
          {
            name:'便利打印',
            tab:'/convenience',
          },
          {
            name:'智能文印店',
            tab:'/intelligentPrintShop',
          },
          {
            name:'关于我们',
            tab:'/porun',
          }
        ],
        problems:[
            {
                title:'1、问题：校方管理（租赁）费等问题',
                content:'流海自助云打印终端的引入为了给校内师生提供更好的文印服务，占据了学校小份额的文印服务，更多的利润回馈了学生（毕业活动，迎新活动等等），跟传统的自动贩卖机，咖啡机等有本质上的区别。',
                content1:'具体管理（租赁）金额联系我们，以实际沟通为准。',
            },
            {
                title:'2、问题：校方担心终端广告屏商业化',
                content:'终端的宣传屏，可以给学校提供校内公益广告、学校宣传片、学风建设宣传片、校园教务通知、公寓水电通知、校园失物招领、毕业生招聘就业信息、勤工助学岗招聘等等一切与学生生活息息相关的校园生活文化视频或宣传片。',
                content1:'承诺以及附属合同内不放播放一切反党反社会，涉黄涉黑的不利于青少年，不利于校园文化建设的广告。',
            },
            {
                title:'3、问题：学生担心用户文件隐私安全以及出现敏感反社会涉黄文件',
                content:'流海采用线上独立账户，系统加密；打印后云端文件在7日后自动销毁，保护隐私；线下终端读取U盘采取“只读不写”，所有插入终端的U盘，不会对U盘写入任何程序，避免U盘中毒。',
                content1:'系统自动识别敏感词汇，后台自动屏蔽该文件，同时系统报警反馈并将该用户拉入预警库，做到该用户下次使用系统将会自动预警。',
            },
            {
                title:'4、问题：流海入校是否会对校内打印店产生冲击？',
                content:'流海的产品定位是便利自助打印，小订单服务，给校内师生提供便捷文印服务的同时，营造良好的学习氛围，提高打印需求。涉及大订单，胶装等需求还是会去打印店，与打印店服务是互补作用，两者相辅相成，所以冲击并不大。',
            },
            {
                title:'5、问题：打印售后是否及时？',
                content:'公司有成熟的运营管理团队，运用人员与已投放高校是一（多）对一管理，并会在校内设立勤工助学运营兼职岗，培养校内合伙人对终端实施管理。',
                content1:'所有终端云端在线监控，故障，巡查、耗材，反馈意见实时可查，终端预警第一时间接收，故障处理时间计时考核，所以可完全放心使用。'
            },
            {
                title:'6、问题：学生如何找到可用终端？',
                content:'终端一般投放在图书馆、教学大厅、寝室楼大厅、食堂等位置，呈网状分布，用户可在流海小程序或APP上实时查询终端位置及可用状态。',
            },
            {
                title:'7、问题：流海入校对学校有哪些好处？',
                content:'流海对投放场地要求简单，只需学校提供电源和摆放的位置（网络自带），就能为学校的整体的教育设施配件水平增色，打造智能化校园。',
                content1:'日常播放校内公益视频和校内通知，增加校内宣传渠道。',
                content2:'对于优秀的校内合伙人，流海也可将该项目赋予为其创新创业项目，培养管理人才。'
            },
            {
                title:'8、问题：流海入校对校内师生有哪些好处？',
                content:'随时打印、随地取件，不用担心打印店打烊，不用担心手边没电脑，手机上传打印文件，任何时间满足打印需求。便捷！快速！',
            },
        ],
        show:true,
        print_shop_problems:[
            {
                title:'1、问题：加盟店怎样保障资金安全？',
                content:'流海联盟是流海为管理人员、加盟商专门开发的管理应用。所有终端的状态、耗材、巡查等实施在线可查。所有的订单、营收、退款、提现信息一目了然，店铺营收采用N+1提现方式，保障加盟店铺资金到账的及时性。',
            },
            {
                title:'2、问题：如何保障店铺数据安全？',
                content:'流海有8年的云打印技术经验，有100+项技术专利和相关的保密技术手段，已打印的文件云端7日后自动销毁，保障用户文档信息安全，保障店铺经营信息绝不外泄',
            },
            {
                title:'3、问题：加盟后，安装和使用软件是否麻烦？',
                content:'流海运营对加盟店进行一对一安装和运营服务，软件安装特别简单，十分钟搞定，软件使用嵌入正常的打印操作中，不知不觉中实现自助收银，先收钱后打印的方式。',
            },
            {
                title:'4、问题：普通电脑和打印机能适配吗？',
                content:'流海的自助软件适配各种型号的电脑和打印机，所以请放心加盟。',
            },
            {
                title:'5、问题：店主能自由设置打印价格吗？',
                content:'可以的！店主有管理员账号，可以自由设置打印、复印、彩印、黑白、彩色、扫描、证件照、照片冲印等的价格，可以设置最低价，方便活动宣传等。',
            },
            {
                title:'6、问题：需要签订合同吗？',
                content:'需要，双方达成一致意见后，可签订纸质或电子合同保障双方利益。',
            },
            {
                title:'7、问题：加盟流程简单吗？',
                content:'很简单。',
                content1:'①联系商务经理填写信息提交申请表格；',
                content2:'②审核信息通过，10分钟安装软件，即装即用；',
                content3:'③专业技术人员QQ远程在线指导使用。'
            },
            {
                title:'8、问题：提供营销活动引流吗？',
                content:'流海会不定期上线迎新、拉新活动，比如充值送鱼籽活动、新用户送优惠券免单活动、一网通、支付宝随机立减活动等，帮助商家引流增收，提高宣传力度。',
            },
        ],
        screenWidth: document.body.clientWidth,
        radio:'高校打印',
        head:true,
        isShow:true,
      })
      const navigate = (url) => {
        router.push(url)
      };
      const getValue = (e) =>{
        console.log(data);
        if(e == '高校打印'){
          data.value.show = true;
        }else if(e == '智能文印店'){
          data.value.show = false;
        }
      }
      onMounted(() => {
        window.scrollTo(0,0);
      });
        return {
            data,
            navigate,
            onMounted,
            getValue,
        }
    }
})
</script>

<style scoped>
.head{
  /* width:1200px; */
  position: relative;
	min-width: 1200px;
	height: auto;
	margin: 0 auto;
  background:#F8F8F8;
}
.header{
  background: #FFFFFF;
}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;
}
.title{
  margin:0 auto;
  width: 1400px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #333333;
}
.item:hover{
  font-size:21px;
  color: #333333;
  opacity:0.5;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 100px 150px 0px 350px;
  overflow: hidden;
  width: 1200px;
}
.word{
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin:150px 0 10px 0;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
.product{
  text-align: center;
  min-width: 1200px;
}
.problem{
    width: 1200px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    margin:80px auto;
}
.pro-con{
    font-size: 20px;
    font-family: SimHei;
    font-weight: 400;
    color: #666666;
    margin-top:30px;
    display:inline-block;
    line-height:50px;
}
.pro-title{
    margin-left:20px;
    font-size: 25px;
    font-family: SimHei;
    font-weight: 400;
    color: #333333;
}
</style>