<template>
    <div class = "head">
        <div class = "company_info">
            <div class="main">
                <el-image  class="machine" :src="require('../../../assets/machine.png')" fit="fill"></el-image>
                <el-image class = "logo"  style=""
                            :src="require('../../../assets/logo-gradual.png')"
                            fit="fill"></el-image>
            </div>
            <div class = "long"></div>
            <div class="footer">
                <div class = "join">导航</div>
                <div class = "foot">
                    <span @click="navigate('/print')">高校打印</span>
                    <span class= "right" @click="navigate('/porun')">关于我们</span>
                </div>
                <div class = "foot">
                    <span @click="navigate('/convenience')">便利打印</span>
                    <span class= "right" @click="toCourse">公司历程</span>
                </div>
                <div class = "foot">
                    <span @click="navigate('/intelligentPrintShop')">智能文印店</span>
                    <span style= "margin-left:60px;" @click="toLocation">位置信息</span>
                </div>
                <div class = "foot"  @click="navigate('/problem')">
                    <span>常见问题</span>
                </div>
                <div class ="footer-right">
                    <div class="qrcodes">
                        <span class = "content">关注我们</span>
                        <div v-for="(item,index) in data.qrcodes" :key="index">
                            <el-image  class="img" :src="item.url" fit="fill"></el-image>
                            <div class = "img-word">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="qrcode">
                        <span class = "business">商务联系</span>
                        <div v-for="(item,index) in data.qrcode" :key="index">
                            <el-image  class="img" :src="item.url" fit="fill"></el-image>
                            <div class = "img-word" style="width:170px;text-align: left;">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter,useRoute} from "vue-router";
export default defineComponent({
    setup () {
      const router = useRouter();
      const route = useRoute();
      const data = ref({
          qrcodes:[
                    {
                    url:require('../../../assets/公众号.png'),
                    name:'公众号'
                },
                    {
                    url:require('../../../assets/微信小程序二维码.png'),
                    name:'小程序'
                },
                    {
                    url:require('../../../assets/流海APP.png'),
                    name:'流海App'
                },
                    {
                    url:require('../../../assets/weibo.png'),
                    name:'微博'
                },             
          ],
          qrcode:[
                    {
                    url:require('../../../assets/relation.png'),
                    name:'高校/便利点/文印店加盟'
                },
                    {
                    url:require('../../../assets/relation2.png'),
                    name:'媒体流量合作'
                },
          ]
      });
       const navigate = (url) => {
        router.push(url)
      };
      const toLocation = () => {
          if(route.path=='/porun'){
            document.documentElement.scrollTop = 4050;
          }else{
            router.push({path:'/porun',query: { sign:1}})
          } 
      }
      const toCourse = () => {
          if(route.path=='/porun'){
            document.documentElement.scrollTop = 2300;
          }else{
            router.push({path:'/porun',query: { sign:2}})
          }
      }
      onMounted(()=>{
            window.scrollTo(0,0);
            if(route.query.sign == 1){
                document.documentElement.scrollTop = 1650;
            }else if(route.query.sign == 2){
                document.documentElement.scrollTop = 1900;
            }
      })
      return {
        data,
        navigate,
        toLocation,
        toCourse,
      }
    }
})
</script>

<style scoped>
.head{
    width: 100%;
    position: relative;
	min-width: 1200px;
    height: auto;
	margin: 0 auto;
}
.company_info{
    min-width: 1200px;
    height: 400px;
    padding-top:1px;
    background:  linear-gradient(90deg, #f6aa34, #ff7619);
}
.join{
    font-size: 21px;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
    display: inline-block;
    position: relative;
    padding-bottom: 50px;
}
.content{
    width: 100px;
    position: absolute;
    font-size: 21px;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-top:-50px;
}
.business{
    width: 100px;
    position: absolute;
    font-size: 21px;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-top:-50px;
}
@media screen and (max-width: 1600px) {
    .machine{
        display: none;
    }
    .logo{
        display: none;
    }
    .long{
        display: none;
    }
}
.logo{
     position: absolute;
     width: 133px;
     height: 30px;
     margin: 200px 0 0 150px;
}
.machine{
    position: absolute;
    width: 40px;
    height: 60px;
    margin: 100px 0 0 200px;
}
.long{
    position: absolute;
    border: 1px solid #FFFFFF;
    width: 0px;
    height: 300px;
    margin: 0px 0 0 500px;
}
.footer{
    min-width: 1200px;
    height: auto;;
    font-size: 18px;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0px auto;
    margin-left: 400px;
    float:right;
}
.footer-right{
    height:auto;
    margin-top:-290px;
    /* margin: 0px auto; */
    float:right;
}
.foot{
    margin-bottom:45px;
    cursor: pointer;
}
.right{
    margin-left:80px;
}
.qrcodes{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.qrcode{
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: 60px;
}
.img{
    width: 100px;
    height: 100px;
    margin: 0 100px 10px 0;
}
.img-word{
    text-align: center;
    font-size: 15px;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
    width: 100px;
    flex-wrap: wrap;
}
.main{
    margin-top:20px;
}
</style>