import { createStore } from 'vuex'

export default createStore({
  state: {
    isPC:false
  },
  mutations: {
    //判断是否为PC端
      SET_MOBLIE(state,isPC){
        state.isPC = isPC;
    }
  },
  actions: {
  },
  modules: {
  }
})
