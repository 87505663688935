import Cookies from 'js-cookie';

const base_ip = "http://ykglapi.yinka.co";//正式环境
// const base_ip = "http://dkadminapi.newhigh.net";//测试环境
const fetchUtil = {
  header: undefined,
  post(url, data, header) {
    if (Cookies.get("token") != undefined) {
      header = {
        access_token: Cookies.get("token")
      }
    }
    if (header != undefined) {
      this.header = header;
    } else {
      header = this.header;
    }
    return fetch(base_ip + url, {
      body: JSON.stringify(data),
      headers: header,
      method: "POST",
      mode: "cors",
      credentials: "same-origin"
    }).then(response => response.json());
  },
  get(url, data, header) {
    if (Cookies.get("token") != undefined) {
      header = {
        access_token: Cookies.get("token")
      }
    }
    if (header != undefined && header.access_token != undefined) {
      this.header = header;
    } else {
      header = this.header;
    }
    let temp = "";
    for (let item in data) {
      if (item != "access_token")
        temp += (item + "=" + data[item] + "&").trim();
    }
    temp = temp.substr(0, temp.length - 1);
    return fetch(base_ip + url + "?" + temp, {
      headers: header,
      method: "GET",
      mode: "cors",
      credentials: "same-origin" //same-origin include
    }).then(response => response.json());
  },
  postData(url, data, header) {
    if (header != undefined) {
      this.header = header;
    } else {
      header = this.header;
    }
    return fetch(url, {
      body: JSON.stringify(data),
      headers: header,
      method: "POST",
      mode: "cors",
      credentials: "same-origin"
    }).then(response => response.json());
  },
  getData(url, data, header) {
    if (header != undefined && header.access_token != undefined) {
      this.header = header;
    } else {
      header = this.header;
    }
    let temp = "";
    for (let item in data) {
      if (item != "access_token")
        temp += (item + "=" + data[item] + "&").trim();
    }
    temp = temp.substr(0, temp.length - 1);
    return fetch(url + "?" + temp, {
      headers: header,
      method: "GET",
      mode: "cors",
      credentials: "same-origin" //same-origin include
    }).then(response => response.json());
  }
};

export default fetchUtil;
