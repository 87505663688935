<template>
    <div class="head">
        <div class="header">
          <div class="title">
            <el-image  class="icon" :src="require('../../assets/porun.png')" fit="cover" width="100vw"></el-image>
              <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
                  <span  @click="navigate(item.tab)">{{item.name}}</span>
              </p>
          </div>
          <el-image  style="width: 100%; height: auto;position:absolute;z-index:-1;margin-top:-100px;" :src="require('../../assets/school/bg.png')" fit="cover"></el-image>
        </div>
        <div style="min-width:1200px;margin:0 auto">
          <el-button color="#FFFFFF" @click="toBottom" class ="experience-btn">立即体验 <div class="box"></div></el-button>
        </div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class = "main">
            <div v-for ="(i,index) in data.info" :key="index">
            <div style="font-size: 40px;font-family: SimHei;font-weight: 400;color: #333333;">{{i.num}}</div>
            <div style="font-size: 23px;font-family: SimHei;font-weight: 400;color: #666666;">{{i.name}}</div>
            </div>
        </div>
        <div class="word">
            业务简介
        </div>
    
        <div class="word-content">
            <p>
                <span style="font-size:22px;color:#FD8420;font-weight:bold">流海云印    </span>集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。
                <br/>
                产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭
                环生态云系统。
                <br/>
                流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。
            </p>
            <el-image style="margin-top:50px;" :src="require('../../assets/school/printSteps.png')" fit="fill" width="100%"></el-image>
        </div>

        <div class="word">
          解决问题
        </div>
        <div class = "solveProblem">
            <p>传统的高校文印模式在信息化高速发展、人才培养模式急需变革的今天，已经无法满足师生日常需求</p>
        </div>
        <div class = "solveProblemImg">
        </div>
         
        <div class="problemImg">
            <div  v-for="(item,index) in data.solve" :key="index">
                <el-image style="width:200px;height:auto;" :src="item.url" fit="cover" width="100%"></el-image>
                <div class="problemImg-word">
                    <p style="text-align:center;padding-top:10px;">{{item.problem}}</p>
                </div>
            </div>
        </div>
        <div class="word" style="margin:40px 0px;">
          <el-image style="width:100px;height:130px" :src="require('../../assets/school/vs.png')" fit="fill" width="100%"></el-image>
        </div>

        <div style="display:flex;justify-content: space-around;align-items: center;width:1200px;margin:0 auto;">
            <div class="problemImg-word" style="font-size: 20px;line-height:30px;width:220px;background: linear-gradient(180deg, #F4A223, #FF6000);border-radius:20px;" v-for="(item,index) in data.advantage" :key="index">
                <p style="text-align:center;padding-top:10px;">{{item.title}}</p>
            </div>
        </div>
        <div class="word">
          产品优势
        </div>
            
        <div class = "product-title">
            <p>流海给高校带来了哪些惊喜服务？打造“互联网+教育”智慧校园，给你意想不到的精彩！</p>
        </div>

        <div style="  display: flex;justify-content: space-around;min-width:1200px;magrin:0 auto;">
          <div class="product">
            <el-image style="width:150px;height:190px" :src="require('../../assets/school/serve.png')" fit="fill"></el-image>
            <p class="product-superiority">24h*7打印服务</p>
            <p class="product-content">文件一键上传校园终端分散式布点随时打印，随地取件</p>
          </div>
           <div class="product" style="padding-top:200px;">
            <el-image style="width:150px;height:190px" :src="require('../../assets/school/manage.png')" fit="fill"></el-image>
            <p class="product-superiority">科学防疫管理</p>
            <p class="product-content">终端每日消毒 无触摸取件、预约取件 云端实时监控</p>
          </div>
           <div class="product">
            <el-image style="width:150px;height:190px" :src="require('../../assets/school/business.png')" fit="fill"></el-image>
            <p class="product-superiority">勤工助学创新创业</p>
            <p class="product-content">提供在校勤工助学岗 支持校内创新创业团队 技术开发、市场推广实践</p>
          </div>
           <div class="product" style="padding-top:200px;">
            <el-image style="width:150px;height:190px" :src="require('../../assets/school/media.png')" fit="fill"></el-image>
            <p class="product-superiority">流海数字传媒</p>
            <p class="product-content">校园通知 失物招领、公益宣传 丰富校园生活</p>
          </div>
           <div class="product">
            <el-image style="width:150px;height:190px" :src="require('../../assets/school/school.png')" fit="fill"></el-image>
            <p class="product-superiority">校园增值服务</p>
            <p class="product-content">论文查重、校园文库 快捷学习工具 海量学习资源共享</p>
          </div>
        </div>

        <div class="word">
          设备信息
        </div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class = "content">
            <div style="width: 50%;min-width:600px;background:#FEF6F1">
                <el-image  style="min-width:400px;height: auto;padding:45px 100px;" lazy :src="require('../../assets/school/water.png')" fit="fill"></el-image>
            </div>
            <div style="width: 50%;min-width:500px;margin-top:50px;">
              <p class="school-title" style="margin-left:50px;">
                水滴-SD315
              </p>
              <p class = "school-main" style="margin-left:50px;">
                <span class="blod">基本性能：</span>支持A4黑白/彩色/单/双面混合打印，6寸照片冲印，打印速度约30页/min，冲印速度：20s/张
                <br/>
                <span class="blod">配置：</span>15寸触摸屏，高性能EPSON喷墨打印机（纸盒：750张A4纸）
                <br/>
                <span class="blod">耗能：</span>终端正常工作需要24h220V供电。经实际使用检测：<br/>
                <span style="margin-left:70px;">打印模式功耗：约22W（IOS/IEC24712）</span> <br/>
                <span style="margin-left:70px;">待机模式功耗：约0.2W </span><br/>
                <span style="margin-left:70px;">屏幕工作功耗：20W </span><br/>
                <span style="margin-left:70px;">终端全年耗电量为：183.2度，平均每日耗电量为：0.5度</span><br/>
                <br/>
                <span class="blod">尺寸：</span>485mm*600mm*1670mm²，占地面积约为：0.48m²，空间面积小于0.48m³
              </p>
            </div>
        </div>
        <div class = "content-right">
          <div style="width: 50%;min-width:600px;">
            <p class="school-title-right" style="width:80%;margin:0 auto">
              探索者-TSZ115
            </p>
            <p class = "school-main-right" style="width:80%;margin:0 auto;margin-top:20px">
              <span class="blod">基本性能：</span>支持A4黑背/彩色/单/双面混合打印，打印速度约30页/min
              <br/>
              <span class="blod">配置：</span>19寸液晶显示屏，15寸触摸屏，EPSON喷墨打印机
              <br/>
              <span class="blod">耗能：</span>终端正常工作需要24h220V供电。经实际使用检测：<br/>
              <span style="margin-left:70px;">打印模式功耗：约22W（IOS/IEC24712）,</span> <br/>
              <span style="margin-left:70px;">待机模式功耗：约0.2W </span><br/>
              <span style="margin-left:70px;">屏幕工作功耗：20W  </span><br/>
              <span style="margin-left:70px;">终端全年耗电量为：183.2度，平均每日耗电量为：0.5度</span><br/>
              <br/>
              <span class="blod">尺寸：</span>747mm*625mm*2000mm²，占地面积约为：0.47m²，空间面积小于0.94m³
            </p>
          </div>
            <div style="width: 50%;min-width:600px;background:#EDFBFC;text-align:center;">
              <el-image  style="width: 400px; height: auto;padding:0px auto;" lazy :src="require('../../assets/school/explore.png')" fit="fill"></el-image>
            </div>
        </div>

        <div class = "content">
            <div style="width: 50%;min-width:600px;background:#F6F6FA">
                <el-image  style="min-width:400px;height: auto;padding:45px 100px;" lazy :src="require('../../assets/school/flow.png')" fit="fill"></el-image>
            </div>
         
            <div style="width: 50%;min-width:500px;margin-top:50px;">
              <p class="school-title" style="width:80%;margin:0 auto">
                水流-SL217/212
              </p>
              <p class = "school-main" style="width:80%;margin:0 auto">
                <span class="blod">基本性能：</span>支持A4黑白/彩色/单/双面混合打印，打印速度约55页/min
                <br/>
                <span class="blod">配置：</span>27寸/32寸触摸屏，高性能惠普页宽打印机（纸盒：1500张A4纸）
                <br/>
                <span class="blod">耗能：</span>终端正常工作需要24h220V供电。经实际使用检测：    <br/>
                <span style="margin-left:70px;">打印模式功耗：约23W（IOS/IEC24712）</span> <br/>
                <span style="margin-left:70px;">待机模式功耗：约0.3W</span><br/>
                <span style="margin-left:70px;">屏幕工作功耗：21W </span><br/>
                <span style="margin-left:70px;">终端全年耗电量为：198.5度，平均每日耗电量为：0.54度</span><br/>
                <br/>
                <span class="blod">尺寸：</span>700mm*625mm*1790mm，占地面积约为：0.44m²，空间面积小于0.79m³
                <br/>
              </p>
            </div>
        </div>

        <div class = "content-right">
          <div style="width: 50%;min-width:600px;">
            <p class="school-title-right" style="width:80%;margin:0 auto">
              开拓者-KTZ112
            </p>
            <p class = "school-main-right" style="width:80%;margin:0 auto;margin-top:20px">
              <span class="blod">基本性能：</span>支持A4黑背/彩色/单/双面混合打印，打印速度约30页/min
              <br/>
              <span class="blod">配置：</span>32寸触摸屏，EPSON喷墨打印机
              <br/>
              <span class="blod">耗能：</span>终端正常工作需要24h220V供电。经实际使用检测：<br/>
              <span style="margin-left:70px;">打印模式功耗：约25W（IOS/IEC24712）,</span> <br/>
              <span style="margin-left:70px;">待机模式功耗：约0.5W </span><br/>
              <span style="margin-left:70px;">屏幕工作功耗：约25W  </span><br/>
              <span style="margin-left:70px;">终端全年耗电量为：292.5度，平均每日耗电量为：0.8度</span><br/>
              <br/>
              <span class="blod">尺寸：</span>747mm*625mm*2000mm，占地面积约为：0.47m²，空间面积小于0.94m²
            </p>
          </div>
            <div style="width: 50%;min-width:600px;background:#EEFDF5;text-align:center;">
              <el-image  style="width: 400px; height: auto;padding:0px auto;" lazy :src="require('../../assets/school/pioneer.png')" fit="fill"></el-image>
            </div>
        </div>


      <div class="word">
        应用程序
        <div class ="down-load" style="opacity: 0;">
          app应用下载
        </div>
      </div>

       <div class = "content">
            <div style="display:flex;min-width:1200px;margin:100px auto">
              <div style="width: 50%;min-withd:600px; height: 800px;">
                  <el-image  style="width: 15%;min-width:200px; height: auto;position: absolute;margin-top:200px;" lazy :src="require('../../assets/school/rectangle.png')" fit="fill"></el-image>
                  <el-image  style="width:70%;min-width:300px; height: auto;" lazy :src="require('../../assets/school/newhighApp.png')" fit="fill"></el-image>
                  <el-image  style="width:10%;min-width:150px; height: auto;position: absolute;margin-top:100px;margin-left:-80px;" lazy :src="require('../../assets/school/orange-line.png')" fit="fill"></el-image>
              </div>
              <div class="school-title" style="font-size:30px;color:#E86D07;width:550px;white-space:normal;">
                流海APP，微信小程序、支付宝小程序 QQ小程序、微信公众号、网站和邮箱
                <p class = "school-main">
                  <span class="blod">文档打印<br/></span>
                  支持word、pdf、ppt、wps等文档格式A4纸
                  <br/><span class="blod">照片冲印</span>
                  <br/>支持6寸照片冲印，高清保真
                  <br/><span class="blod">智能证件照</span>
                  <br/>已有证件照拼版打印功能，无电子照可在线打印、支持蓝底、红底、白底证件照底色。支持1寸、2寸等所有证件照尺寸
                  <br/><span class="blod">复印</span> <br/>支持身份证与文件复印，A4页面排版，高清还原，支持彩色/黑白打印
                  <br/><span class="blod">论文服务 </span>
                  <br/>流海论文查重对接维普和paperdd数据库，一键上传，10分钟出结果。准确、高效、性价比高
                  <br/><span class = "btn">用户端</span>
                </p>
              </div>
            </div>
        </div>

        <div class = "content-right" style="margin-top:-200px;min-width:1200px;display:flex;margin:0px auto">
            <div class="school-title-right" style="color:#384368;font-size:30px;color:#E86D07;width:550px;white-space:normal;">
              流海联盟微信小程序
              <p class = "school-main-right">
                <span class="blod-phone">终端数据<br/></span>
                <br/>实时监控终端状态，故障原因及报修等
                <br/><span class="blod-phone">经营管理</span>
                <br/>管理者可设置创建网点信息，包括位置、价格、功能等，可实时查询订单信息及经营数据
                <br/><span class="blod-phone">物资管理</span>
                <br/>所有耗材的申请、发货、入库、消耗、结余等可视化
                <br/><span class="blod-phone">N+1提现</span>
                <br/>管理员申请后，收益次日到账，自动打款
                <br/><span class="blod-phone">分区管理 </span>
                <br/>按类型划分学校、社区、文印店界面信息、数据清晰可查
                <br/><span class = "btn" style="background:#6E77BA;margin-top:20px;">商户端</span>
              </p>
            </div>
          <div style="width: 50%;min-width:600px; height: 800px;">
            <div>
                <el-image  style="width: 15%;min-width: 150px; height: auto;position: absolute;margin-left:20px;margin-top:150px;" lazy :src="require('../../assets/school/blue-line.png')" fit="fill"></el-image>
                <el-image  style="width: 60%;min-width: 400px; height: auto;margin-left:150px;" lazy :src="require('../../assets/school/newhighLeague.png')" fit="fill"></el-image>
                <el-image  style="width: 15%;min-width: 150px; height: auto;position: absolute;z-index:-1;margin-left:-300px;margin-top:200px;" lazy :src="require('../../assets/school/ellipse.png')" fit="fill"></el-image>
            </div>
          </div>
        </div>

        <div class = "product-safety">
          <div class="word" style="padding-top:50px">
              产品安全
          </div>
          <div class = "product-title" style="color:#040D52;margin-top:50px;">
              <p>唯一的云打印发明专利拥有者... 申请专利100+项</p><br/>
              <p>质量ISO9001和环境ISO14001管理体系认证、CNAS检验报告、3C、耗材环保评测报告...</p>
          </div>        
          <div class= "materials">
              <div v-for ="(item,index) in data.materials" :key = "index">
                <el-image  :style="{'width':index == 5 ||index ==7 ? '600px' : '250px','height':'350px','margin':'0px 60px'}" :src="item.url" fit="fill"></el-image>
              </div>
          </div>
        </div>

        <div class="word" style="padding-top:50px">
            运维标准
        </div>
        <div class = "operational-standards">
            <div class = "nomal" v-for ="(item,index) in data.normals" :key = "index">
                <el-image  style="width:100px;height:100px" :src="item.url" fit="fill"></el-image>
                <p class ="nomal-con">
                  {{item.content}}
                  <br/>
                  {{item.content1}}
                </p>
                <br v-if="index == 0||index == 3"/>
                <div class ="nomal-title" :style="{'background-color':index == 0 ? '#FD8420':'#999999'}">
                  {{item.title}}
                </div>
            </div>
        </div>

        <div class="word" style="padding-top:50px">
            投放标准
        </div>

        <div class = "puts-standards">
            <div class = "puts" v-for ="(item,index) in data.puts" :key = "index">
                <div class ="puts-title" :style="{'color':index == 0 ? '#FD8420':'#666666'}">
                  {{item.title}}
                </div>
                <div class = "puts-content" :style="{'color':index == 0 ? '#FD8420':'#666666'}">
                  {{item.content}}
                  <br/>{{item.content1}}
                  <br/>{{item.content2}}
                </div>
            </div>
        </div>

        <div class = "puts-standards" style="margin:20px auto;">
          <div v-for ="(item,index) in data.round" :key = "index">
            <div  class = "round" :style="{'background-color':index == 0 ? '#FD8420':'#DCDCDC'}">
              {{item.num}}
            </div>
          </div>
          <div class="dotted-line "></div>
          <el-image  style="width: 40px; height: 40px;margin-top:30px;" lazy :src="require('../../assets/school/go.png')" fit="fill"></el-image>
        </div>

        <div class = "puts-standards">
            <div class = "puts" v-for ="(item,index) in data.other_puts" :key = "index">
                <div class ="puts-title">
                  {{item.title}}
                </div>
                <div class = "puts-content">
                  {{item.content}}
                  <br/>{{item.content1}}
                  <br/>{{item.content2}}
                </div>
            </div>
        </div>

        <div class="word">
            品牌合作伙伴
        </div>
        <div class="map">
          <div style="min-width: 30%;height: auto;background: linear-gradient(-90deg, #FA9643, #6E77BA);border-radius: 20px;"></div>
          <div style="opacity: 0;width:2%;">占个位</div>
          <div style="min-width: 30%;height: auto;background: #FA9643;border-radius: 20px;padding:0 20px;">
            <p class="newhight-map">流海分布图</p>
              <el-image  style="width: 100%; height: auto;" :src="require('../../assets/map.png')" fit="fill"></el-image>
            </div>
          <div style="opacity: 0;width:2%;">占个位</div>
          <div style="min-width: 30%;height: auto;background: linear-gradient(90deg, #FA9643, #6E77BA);border-radius: 20px;"></div>
        </div>

        <div class="word">
            部分合作高校
        </div>
        <div class="school">
            <div v-for ="(i,index) in data.school" :key = "index">
                <el-image  style="width: 180px; height: 45px;margin-left:50px;margin-top:50px;" :src="i.url" fit="fill"></el-image>
            </div>
        </div>
        
        <div class="word">
            ……
        </div>
        <div class="word" style="margin-bottom:50px;margin-top:50px;">
          实景案例
        </div>
        <el-carousel :interval="4000" type="card" height="450px" style="min-width:1200px;">
          <el-carousel-item v-for="(item,index) in data.printImgs" :key="index">
            <el-image  style="width:600px;height:450px" :src="item.url" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
  
        <div class="word">
          加盟流程
        </div>
        <div class = "puts-standards" style="background-color:#6E77BA;padding:80px 0px;min-width:1200px;">
            <div class = "puts" v-for ="(item,index) in data.flows" :key = "index">
                <div class ="puts-title">
                  <el-image  style="width:160px;height:160px" :src="item.url" fit="fill"></el-image>
                  <div class ="puts-title" style="color:#D5ECFF;font-family: Adobe Heiti Std;font-weight: normal;font-size: 28px;">
                    {{item.content}}
                  </div>
                </div>
            </div>
        </div>
          
        <div class="word" style="margin-bottom:50px;">
          加盟优势
        </div>
        <el-divider>
          <span style="font-size:20px;">优质的产品和完善的配套服务是加盟合作的基础</span>
        </el-divider>
          
        <div class = "puts-standards">
            <div class = "puts" v-for ="(item,index) in data.advantages" :key = "index">
                <div class ="puts-title">
                  <el-image  style="width:200px;height:230px" :src="item.url" fit="fill"></el-image>
                </div>
                <div class ="puts-title">
                  {{item.title}}
                </div>
                <div class = "puts-content">
                  {{item.content}}
                  <br/>{{item.content1}}
                  <br/>{{item.content2}}
                </div>
            </div>
        </div>
        <foo></foo>
        <suspension></suspension>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter} from "vue-router";
import foo from '@/views/print/components/foo.vue'
import suspension from '@/views/print/components/suspension.vue'

export default defineComponent({
    components:{
      foo,
      suspension,
    },
    metaInfo: {
      title:'流海云印——高校打印',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、让文印更智能、U盘打印、智能证件照、照片冲印'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
       const router = useRouter(); 
        const data = ref({
        titles:[
          {
            name:'首页',
            tab:'/',
          },
          {
            name:'高校打印',
            tab:'/print',
          },
          {
            name:'便利打印',
            tab:'/convenience',
          },
          {
            name:'智能文印店',
            tab:'/intelligentPrintShop',
          },
          {
            name:'关于我们',
            tab:'/porun',
          }
        ],
        info:[
          {
            name: '投放高校',
            num: "200+",
            url: require('../../assets/school.png'),
          },
          {
            name: '社区文印店',
            num: '100+',
            url: require('../../assets/print-shop.png'),
          },
          {
            name: '流海会员',
            num: '800万',
            url: require('../../assets/people.png'),
          },
          {
            name: '云打印订单',
            num: '5.2亿',
            url: require('../../assets/paper.png'),
          },
          {
            name: '打印纸张',
            num: '20.3亿',
            url: require('../../assets/paper.png'),
          }
        ],
        imgs:[
          {
            url:require('../../assets/1.png'),
          },
          {
            url:require('../../assets/2.png'),
          },
          {
            url:require('../../assets/3.png'),
          },
          {
            url:require('../../assets/4.png'),
          },
          {
            url:require('../../assets/5.png'),
          },
          {
            url:require('../../assets/15.png'),
          },
          {
            url:require('../../assets/6.png'),
          },
          {
            url:require('../../assets/7.png'),
          },
          {
            url:require('../../assets/8.png'),
          },
          {
            url:require('../../assets/9.png'),
          },
          {
            url:require('../../assets/10.png'),
          },
          {
            url:require('../../assets/15.png'),
          },
          {
            url:require('../../assets/11.png'),
          },
          {
            url:require('../../assets/12.png'),
          },
          {
            url:require('../../assets/13.png'),
          },
          {
            url:require('../../assets/14.png'),
          },
          {
            url:require('../../assets/15.png'),
          },
          {
            url:require('../../assets/15.png'),
          },
        ],
        school:[
           {
            url:require('../../assets/清华大学.png'),
          },
          {
            url:require('../../assets/北京大学.png'),
          },
          {
            url:require('../../assets/长安大学.png'),
          },
          {
            url:require('../../assets/中央财经大学.png'),
          },
          {
            url:require('../../assets/中国农业大学.png'),
          },
          {
            url:require('../../assets/中国地质大学.png'),
          },
          {
            url:require('../../assets/湖北大学.png'),
          },
          {
            url:require('../../assets/江苏大学.png'),
          },
          {
            url:require('../../assets/山东大学.png'),
          },
          {
            url:require('../../assets/四川外国语大学.png'),
          },
          {
            url:require('../../assets/重庆理工大学.png'),
          },
          {
            url:require('../../assets/电子科技大学.png'),
          },
          {
            url:require('../../assets/温州大学.png'),
          },
          {
            url:require('../../assets/南昌大学.png'),
          },
          {
            url:require('../../assets/成都大学.png'),
          },
          {
            url:require('../../assets/重庆交通大学.png'),
          },
          {
            url:require('../../assets/重庆大学.png'),
          },
          {
            url:require('../../assets/武汉科技大学.png'),
          },
          {
            url:require('../../assets/兰州大学.png'),
          },
          {
            url:require('../../assets/武汉大学.png'),
          },
          {
            url:require('../../assets/扬州大学.png'),
          },
          {
            url:require('../../assets/西藏大学.png'),
          },
          {
            url:require('../../assets/陕西师范大学.png'),
          },
          {
            url:require('../../assets/桂林理工.png'),
          },
        ],
        solve:[
            {
                problem:'用户体验差',
                url:require('../../assets/school/1.png'),
            },
            {
                problem:'营业时间有限',
                url:require('../../assets/school/2.png'),
            },
            {
                problem:'纸张差、乱收费',
                url:require('../../assets/school/3.png'),
            },
            {
                problem:'无用户隐私',
                url:require('../../assets/school/4.png'),
            },
            {
                problem:'交叉感染风险大',
                url:require('../../assets/school/5.png'),
            },
        ],
        advantage:[
            {
                title:'自助文印服务',
            },  
            {
                title:'校园分布式24h*7服务',
            },    
            {
                title:'流海统一收费标准',
            },    
            {
                title:'独立账户加密保护',
            },    
            {
                title:'无触摸取件，科学防疫',
            },
        ],
        materials:[
          {
              url:require('../../assets/school/materials1.png'),
          },
          {
              url:require('../../assets/school/materials2.png'),
          },
          {
              url:require('../../assets/school/materials3.png'),
          },
          {
              url:require('../../assets/school/materials4.png'),
          },
          {
              url:require('../../assets/school/materials5.png'),
          },
          {
              url:require('../../assets/school/materials6.png'),
          },
          {
              url:require('../../assets/school/materials7.png'),
          },
          {
              url:require('../../assets/school/materials8.png'),
          },
        ],
        normals:[
          {
            content:'定期清理消毒保养',
            content1:'废弃物处理，保持外观整洁',
            url:require('../../assets/school/norm1.png'),
            title:'01 机柜标准',
          },
          {
            content:'所有耗材的出库、消耗记录可视可查，定期抽查打印纸张，严控打印质量',
            url:require('../../assets/school/norm2.png'),
            title:'02 物资管理',
          },
          {
            content:'每日巡查，加纸加墨 手机终端监控，故障报修',
            content1:'保证终端可用',
            url:require('../../assets/school/norm3.png'),
            title:'03 日常运维',
          },
          {
            content:'400全国专线',
            content1:'24h移动端信息反馈专线',
            url:require('../../assets/school/norm4.png'),
            title:'04 售后退款',
          }
        ],
        puts:[
          {
            title:'供电',
            content:'需提供24h',
            content1: '持续220V交流电',
          },
           {
            title:'学校属性',
            content:'双一流院校',
            content1:'普通本科院校',
            content2: '优质专科院校（10000+人）',
          },
           {
            title:'终端点位',
            content:'教学楼、宿舍楼主入口',
            content1:'其次选择图书馆',
            content2:'实验楼出入通道'
          },
           {
            title:'传统文印店',
            content:'校内及周围实体文印店',
            content1:'分布及价格情况'
          }
        ],
        round:[
          {
            num:'01',
          },
          {
            num:'02',
          },
          {
            num:'03',
          },
          {
            num:'04',
          },
          {
            num:'05',
          },
          {
            num:'06',
          },
              {
            num:'07',
          }
        ],
        other_puts:[
          {
            title:'场地',
            content:'为避免打印机纸张受潮',
            content1: '终端机受损，终端需放置',
            content2: '于通风、干燥的室内或大厅',
          },
           {
            title:'学生人数',
            content:'本科院校800人/台',
            content1:'专科1500人/台的覆盖率',
          },
           {
            title:'专业性质',
            content:'优先选择理工、综合',
            content1:'医科专业学生居住的',
            content2:'宿舍楼投放'
          },
        ],
        printImgs:[
          {
            url:require('../../assets/printShop/img1.png'),
            content:'清华大学-汽车研究院'
          },
          {
            url:require('../../assets/printShop/img2.png'),
            content:'清华大学-汽车研究院'
          },
          {
            url:require('../../assets/printShop/img3.png'),
            content:'清华大学-汽车研究院'
          },
        ],
        flows:[
          {
            url:require('../../assets/flow1.png'),
            content:'选择加盟'
          },
          {
            url:require('../../assets/flow2.png'),
            content:'公司审核'
          },
          {
            url:require('../../assets/flow3.png'),
            content:'签署加盟协议'
          },
          {
            url:require('../../assets/flow4.png'),
            content:'加盟成功'
          },
          {
            url:require('../../assets/flow5.png'),
            content:'获得收益'
          },
        ],
        advantages:[
          {
            title:'投资放心',
            content:'回报周期短',
            content1: '加盟政策优惠',
            content2: '投资风险小',
            url:require('../../assets/advantage1.png'),
          },
          {
            title:'收益稳定',
            content:'市场刚需',
            content1:'用户粘性大',
            content2:'N+1提现',
            url:require('../../assets/advantage2.png'),
          },
          {
            title:'配套服务',
            content:'技术有专利',
            content1:'服务体系完备',
            content2:'运营有保障',
            url:require('../../assets/advantage3.png'),
          },
          {
            title:'数据透明',
            content:'流海联盟',
            content1:'数据实时可查',
            content2:'收益一目了然',
            url:require('../../assets/advantage4.png'),
          },
          {
            title:'品牌保障',
            content:'云打印领跑者',
            content1:'8年运营经验',
            content2:'百家加盟商',
            url:require('../../assets/advantage5.png'),
          },
        ],
      })
      const navigate = (url) => {
        router.push(url)
      };
      const toBottom = ()=>{
        window.scrollTo(0,document.body.scrollHeight);
      }
      onMounted(()=>{
            window.scrollTo(0,0);
      })
        return {
            data,
            navigate,
            toBottom,
        }
    }
})
</script>

<style scoped>
.header{
  width: 100%;
  position: relative;
  min-width: 1200px;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
}
@media screen and (max-width: 1200px) {
    .video {
        display: none;
    }
}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;
}
.title{
  margin:0 auto;
  width: 1200px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #FFFFFF;
}
.item:hover{
  font-size:21px;
  color: #FFFFFF;
  opacity:0.8;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 150px auto;
  overflow: hidden;
  width: 1200px;
}
.word{
  min-width:1200px;
  margin: 0 auto;
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:150px;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
.content{
  display:flex;
  justify-content:space-around;
  min-width:1200px;
}
.school-title{
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  white-space:nowrap;
  
}
.school-main{
  width: 550px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding:50px 0 0 0;
}
.blod{
  font-size: 20px;
  font-weight: bold;
}
.blod-phone{
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-top:30px;
}
.content-right{
  display:flex;
  justify-content:space-around;
  min-width:1200px;
}
.school-title-right{
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  width: 350px;
  margin:0 auto;
}
.school-main-right{
  width: 600px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  margin:0 auto;
}
.cooperation{
  display:flex;
  justify-content: start;
  /* flex-direction: row; */
  flex-wrap: wrap;
  margin-left:300px;
  margin-right:300px;
  margin-top:50px;
}
.map{
  width:95%;
  margin:100px auto;
  display:flex;
  min-width:1200px;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow:hidden;
}
.newhight-map{
  text-align:center;
  font-size: 40px;
  margin-top:80px;
  font-family: SimHei;
  font-weight: 400;
  color: #FFFFFF;
}
.school{
  min-width:1200px;
  display:flex;
  justify-content: start;
  /* flex-direction: row; */
  flex-wrap: wrap;
  margin:50px auto;
}
.word-omit{
  text-align:center;
  font-size: 40px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:60px;
}
.join{
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #FD8420;
  margin: 150px 0 0 250px;
}
.join-main{
  width: 150px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
  margin: 50px 0 0 150px;
}
.join-title{
  font-size: 25px;
  font-family: SimHei;
  font-weight: 400;
  color: #FD8420;
}
.join-content{
  width: 474px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  margin-top:70px;
}
.join-foot{
  width: 200px;
  height: 50px;
  color:#FFFFFF;
  background: #FD8420;
  border-radius: 30px;
  line-height:50px;
  text-align:center;
  margin-top:50px;
}
.join-img{
  width: 500px;
  height: 500px;
  background: rgba(255, 255, 255, 0);
  border: 3px solid rgba(226, 226, 226, 0.27);
  border-radius: 50%;
  margin-top:-550px;
  margin-left:1050px;
  
}
.join-img-three{
  width: 250px;
  height: 250px;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #D8EDF4;
  box-shadow: 0px 5px 50px 0px rgba(63, 159, 197, 0.2);
  border-radius: 50%;
  margin:120px 0 0 120px;
}
.word-content{
    width: 1200px;
    font-size: 23px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
    line-height:50px;
}
.solveProblem{
    width: 993px;
    font-size: 22px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
}
.solveProblemImg{
    width: 1200px;
    height: 300px;
    background: linear-gradient(180deg, #8099FE, #6581FE);
    opacity: 0.4;
    border-radius: 20px;
    margin:50px auto;
}
.problemImg{
    width:1200px;
    margin:0 auto;
    display:flex;
    border-radius: 20px;
    margin-top:-250px;
    justify-content: space-around;
    align-items: center;
}
.problemImg-word{
    width:200px;
    height: 50px;
    background: linear-gradient(180deg, #8099FE, #6581FE);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    border-radius: 0px 0px 20px 20px;
    font-size: 22px;
    font-family: SimHei;
    color: #FFFFFF;
}
.product-title{
    width: 800px;
    font-size: 22px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
    text-align: center;
}
.product-superiority{
  font-size: 25px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #333333;
  text-align: center;
}
.product-content{
  font-size: 22px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-top:20px;
}
.product{
  width:200px;
  height:320px;
  margin: 50px 50px 0px 50px;
}
.down-load{
  font-size: 25px;
  font-weight: 400;
  color: #FD8420;
  margin-top:10px;
  }
  .btn{
    background:#FD8420;
    width:200px;
    display:inline-block;
    height:60px;
    border-radius:30px;
    text-align:center;
    line-height:60px;
    color:#FFFFFF
  }
  .product-safety{
    background-color: #6E77BA;
    margin-top:-50px;
    min-width:1200px;
  }
  .materials{
    display:flex;
    justify-content: start;
    flex-wrap: wrap;
    height:auto;
    overflow:hidden;
  }
  .nomal{
    width: 250px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    text-align:center;
    padding-top:20px;
    /* margin-left:70px; */
  }
  .nomal-title{
    background: #FD8420;
    border-radius: 0px 0px 20px 20px;
    line-height:50px;
    margin-top:15px;
    color:#FFFFFF;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    width:250px;
    height:50px;
  }
  .nomal-con{
    font-size: 15px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #333333;
    text-align:center;
    margin: 0px 20px 0px 20px;
  }
  .operational-standards{
    min-width:1200px;
    display:flex;
    justify-content:space-around;
    margin-top:100px;
  }
  .puts-standards{
    display:flex;
    justify-content:space-around;
    margin-top:100px;
    min-width:1200px;
  }
  .puts-title{
    text-align:center;
    font-size: 30px;
    font-family: SimHei;
    font-weight: bold;
    color: #666666;
  }
  .puts-content{
    text-align:center;
    margin-top:30px;
    font-size: 22px;
    font-family: SimHei;
    font-weight: 400;
    color: #666666;
  }
  .puts{
    width:270px;
  }
  .round{
    width: 100px;
    height: 100px;
    line-height:100px;
    border-radius: 50%;
    font-size: 40px;
    font-family: FangSong;
    font-weight: 400;
    color: #FFFFFF;
    text-align:center;
  }
  .dotted-line {
    z-index:-1;
    position:absolute;
    width: 90%;
    min-width:1080px;
    height: 1px;
    margin:50px auto;
    border-bottom: 2px dashed #FD8420;
  }
  .el-carousel__item{
    line-height: 450px;
    margin: 0;
    text-align: center;
  }
  .experience-btn{
  margin-top:25%;
  margin-left:10%;
  width:250px;
  height:80px;
  color: #FE7B0F;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
}
.box{
  border-style: solid;
  border-width: 15px 15px 15px 20px;
  border-color: transparent transparent transparent #FE7B0F;
  width: 0px;
  height: 0px;
  margin-left:10px;
}
</style>