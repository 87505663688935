<template>
    <div class="head">
        <div class="header">
        <div class="title">
          <el-image  class="icon" :src="require('../../assets/porun.png')" fit="fill" width="100%"></el-image>
            <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
                <span  @click="navigate(item.tab)">{{item.name}}</span>
            </p>
        </div>
        <el-image  style="width: 100%; height: auto;position:absolute;z-index:-1;margin-top:-100px;" :src="require('../../assets/porun/bg.png')" fit="cover"></el-image>
        <div class="official">
            颇闰科技
        </div>
        <div class="official" style="font-size: 36px;font-weight: 400;">
            高新技术企业、先进示范企业…
        </div>
        <div class="official" style="font-size: 36px;font-weight: bold;">
            专注自助云印、数字传媒和增值服务
        </div>
        <div class ="official">
          <el-button color="#FD8420" @click="toBottom" class ="experience-btn">联系我们 <div class="box"></div></el-button>
        </div>
        </div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word" style="opacity: 0;">占个位</div>
        <suspension></suspension>
       <div class = "content">
            <div style="width:600px;">
                <el-image  style="width: 500px; height: auto;" :src="require('../../assets/porun/5.png')" fit="cover"></el-image>
            </div>
            <p class="company-title">
              公司简介
            </p>
            <p class ="company-name">Company profile</p>
            <p class = "company-main">
              重庆颇闰科技有限公司 成立于2013年11月，是一家专注于网络自助服务系统研发与运营、校园数字传媒、大数据挖掘的移动互联网公司。
              <br/>
              2021年，团队在国内率先提出“随时打印、随地取件、安全高效”的云打印概念，“流海云印”自投入市场后，一直得到政府、资本市场的高度认可，目前公司已获评国家高新技术企业、重庆市创新创业重点扶持单位的称号，为满足市场需求，公司已设立颇闰科技江苏子公司、颇闰科技湖北子公司。
              <br/>
              目前，流海云印已经在全国23个省市近200多所高校校区以及数百家社区投入商业运营，校内终端2000多台，线上会员300多万，每日用户访问量达百万，为500万高校师生提供校园便捷云服务。目前，颇闰已经完成A+轮融资，融资金额3000多万元，市场估值3亿元。流海作为高校校园云打印服务商，市场占有率相当于主要竞争对手市场总和的2倍多。
            </p>
        </div>
   
        <div class="word">
            企业文化
        </div>
        <div class = "operational-standards">
            <div class = "nomal" v-for ="(item,index) in data.products" :key = "index">
                <el-image  style="width:130px;height:130px" :src="item.url" fit="fill"></el-image>
                <p class ="nomal-con">{{item.title}}</p>
                <br/>
                <div class ="nomal-title">
                  {{item.content}}
                  <br/>{{item.content1}}
                </div>
            </div>
        </div>
        <div class="word">
            公司历程
        </div>
        <div class ="course">
          <div class ="course-left">
            <div class="course-item">
              <div class="course-item-title">2020年10月</div>
              <div class="course-item-content">入驻巴南先进科技创新中心。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/7.png')" fit="cover"></el-image>
            </div>
            <div class="course-item">
              <div class="course-item-title">2016年9月</div>
              <div class="course-item-content">获的天使轮投资，入驻重庆市九龙坡区清研理工创业谷。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/4.png')" fit="cover"></el-image>
            </div>
            <div class="course-item">
              <div class="course-item-title">2014年6月</div>
              <div class="course-item-content">第一批云打印自助终端正式在重庆理工大学试点运营。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/6.png')" fit="cover"></el-image>
            </div>
            <div class="course-item">
              <div class="course-item-title">2012年</div>
              <div class="course-item-content">【互联网+硬件】云打印理念萌芽于电子创新实验室，组建[云打印项目组]。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/1.png')" fit="cover"></el-image>
            </div>
          </div>

          <div class ="course-center">
            <span class ="line"></span>
            <span class ="center-block">2020年10月</span>
            <div style="width: 1px;height: 100px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="center-block">2018年5月</span>
            <span class ="line" style="margin-left:200px;margin-top:-15px;"></span>
            <div style="width: 1px;height: 100px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="center-block">2017年8月</span>
            <span class ="line" style="margin-left:200px;margin-top:-15px;"></span>
            <div style="width: 1px;height: 100px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="line"></span>
            <span class ="center-block">2016年9月</span>
            <div style="width: 1px;height: 100px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="center-block">2015年3月</span>
            <span class ="line" style="margin-left:200px;margin-top:-15px;"></span>
            <div style="width: 1px;height: 200px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="line"></span>
            <span class ="center-block">2014年6月</span>
            <div style="width: 1px;height: 150px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="center-block">2013年11月</span>
            <span class ="line" style="margin-left:200px;margin-top:-15px;"></span>
            <div style="width: 1px;height: 150px;background: #1B91FF;margin-left:120px;margin-top:10px;margin-bottom:25px;"></div>
            <span class ="line"></span>
            <span class ="center-block">2012年</span>
          </div>

          <div class="course-right">
            <div class="course-right-item">
              <div class="course-item-title-right" style="margin-top:120px;">2018年5月</div>
              <div class="course-item-content-right">获得A+轮融资数千万。</div>
            </div>
            <div class="course-right-item">
              <div class="course-item-title-right">2017年8月</div>
              <div class="course-item-content-right">获得A轮融资数千万，升级品牌为【流海云印】</div>
            </div>
            <div class="course-item" style="margin-top:150px;">
              <div class="course-item-title" style="float:left">2015年3月</div>
              <div class="course-item-content">获得种子轮融资，确定云打印品牌名为【印咖】，入驻重庆市巴南区极速超越创客空间。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/3.png')" fit="cover"></el-image>
            </div>
            <div class="course-item">
              <div class="course-item-title" style="float:left">2013年11月</div>
              <div class="course-item-content" >2013.11颇闰科技成立（颇闰取Print谐音），入驻重庆理工大学生创业孵化园，被评为大学生创业重点扶持项目。</div>
              <el-image  style="width: 320px; height: auto;margin-left:15px;margin-bottom:15px;" :src="require('../../assets/porun/2.png')" fit="cover"></el-image>
            </div>
          </div>
        </div>
        <div style="display:flex;width:1200px;margin:0 auto;">
          <div style="width:500px;autopx;margin:200px auto;">
            <div class="name-title">
              重庆颇闰科技有限公司
            </div>
            <div class ="name">
              联系方式：
            </div>
            <div class ="name" style="margin-top:10px">
              185 2300 5450
            </div>
            <div class ="name">
              邮箱：
            </div>
            <div class ="name" style="margin-top:10px">
              porun@cqporun.com
            </div>
            <div class ="name">
              地址: 
            </div>
            <div class ="name" style="margin-top:10px">
              重庆市巴南区红光大道73号（先进技术创新中心企业楼1楼）
            </div>
            
          </div>
          <div id="container"></div>
        </div>
        
        <foo></foo>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter} from "vue-router"
import suspension from '@/views/print/components/suspension.vue'
import foo from '@/views/print/components/foo.vue'
export default defineComponent({
    components:{
      suspension,
      foo
    },
    metaInfo: {
      title:'流海云印——关于我们',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、颇闰科技有限公司、高新技术企业、先进示范企业、专注自助云印、数字传媒和增值服务。'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
        const router = useRouter(); 
            const data = ref({
            titles:[
                {
                    name:'首页',
                    tab:'/',
                },
                {
                    name:'高校打印',
                    tab:'/print',
                },
                {
                    name:'便利打印',
                    tab:'/convenience',
                },
                {
                    name:'智能文印店',
                    tab:'/intelligentPrintShop',
                },
                {
                    name:'关于我们',
                    tab:'/porun',
                }
            ],
            products:[
                {
                    content:'以人为本，创新务实',
                    content1:'用户放心，客户开心，员工舒心',
                    url:require('../../assets/porun/Purpose.png'),
                    title:'颇闰的宗旨',
                },
                {
                    content:'专注云打印',
                    content1:'实现各种场景下的自助打印智能化',
                    url:require('../../assets/porun/Mission.png'),
                    title:'愿景和使命',
                },
                {
                    content:'信誉至上，诚信经营',
                    content1:'打造智能化云服务平台',
                    url:require('../../assets/porun/Ideas.png'),
                    title:'经营理念',
                },
            ],
            radio:'高校打印',
        })
        const navigate = (url) => {
            router.push(url)
        };
        const toBottom = ()=>{
          document.documentElement.scrollTop = 4050;
        }
        const initMap = ()=>{
            //定义地图中心点坐标     
            var center = new window.TMap.LatLng(29.458892,106.526682)
            
            var map = new window.TMap.Map(document.getElementById('container'), {
                center: center,//设置地图中心点坐标
                zoom: 18,   //设置地图缩放级别
                mapStyleId: 'style2', //设置地图样式  
            });
            var marker = new window.TMap.MultiMarker({
                map: map,
                styles: {
                marker: new TMap.MarkerStyle({
                        width: 20, // 样式宽
                        height: 30, // 样式高
                        anchor: { x: 10, y: 30 }, // 描点位置
                    }),
                },
                geometries: [
                    {
                        // 标记位置(纬度，经度，高度)
                        position: center,
                        id: 'marker',
                    },
                ],
            })
            var infoWindow = new window.TMap.InfoWindow({
                map: map, 
                position: center, //设置信息框位置
                offset: { x: 0, y: -50 },
                content: '重庆颇闰科技有限公司（巴南先进技术创新中心企业楼一楼）' //设置信息框内容
            });
            infoWindow.close();//初始关闭信息窗关闭
            marker.on("click", function (evt) {
                //设置infoWindow
                infoWindow.open(); //打开信息窗
            })
        }
        onMounted(()=>{
            initMap();
        })
        return {
            data,
            navigate,
            toBottom,
        }
    }
})
</script>

<style scoped>
.header{
  width: 100%;
  position: relative;
  min-width: 1200px;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
}
@media screen and (max-width: 1200px) {
    .video {
        display: none;
    }

}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;
  opacity: 1;
}
.title{
  margin:0 auto;
  width: 1200px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #FD8420;
}
.item:hover{
  font-size:21px;
  color: #FD8420;
  opacity:0.8;
}
.official{
    width:50%;
    font-size: 60px;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
    text-align:center;
    margin:40px auto;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
  overflow: hidden;
  width: 1200px;
}
.word{
  min-width:1200px;
  margin: 0 auto;
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:150px;
}
.experience-btn{
  width:250px;
  height:80px;
  color: white;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
}
.box{
  border-style: solid;
  border-width: 15px 15px 15px 20px;
  border-color: transparent transparent transparent white;
  width: 0px;
  height: 0px;
  margin-left:10px;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
#container {
  width: 600px;
  height: 600px;
  margin:100px auto;
}
.content{
  width:1200px;
  margin:200px auto;
}
.company-title{
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  white-space:nowrap;
  margin-top:-400px;
  margin-left:600px;
}
.company-name{
    font-size: 30px;
    font-family: Arial;
    font-weight: bold;
    color: #999999;
    text-align: center;
    margin-left:220px;
    margin-top:20px;
}
.company-main{
  width: 600px;
  font-size: 18px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  margin-left:600px;
  margin-top:20px;
}
.company-title:after{
  clear:both
}
.nomal{
  width: 350px;
  text-align:center;
  margin-left:70px;
}
.nomal-title{
  font-size: 22px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
  line-height: 36px;
}
.nomal-con{
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 35px;
  font-weight: normal;
  color: #333333;
  text-align:center;
  margin: 20px 20px 10px 20px;
}
.operational-standards{
  display:flex;
  width:1200px;
  margin:0 auto;
  justify-content:center;
  margin-top:100px;
}
.course{
  width:1200px;
  display:flex;
  justify-content:center;
  margin:0 auto;
}
.course-left{
  margin-top:100px;
}
.course-item{
  width:350px;
  height:auto;
  margin-bottom:100px;
  background-color:#F1F1F1;
}
.course-item-title{
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: #333333;
  float:right;
  margin:15px;
}
.course-item-content{
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
  line-height: 25px;
  float:right;
  margin:0 15px 15px 15px;
}
.course-right{
  margin-top:150px;
}
.course-center{
  margin-top:150px;
}
.line{
  width: 30px;
  height: 1px;
  display:block;
  background: #1B91FF;
  margin-left:20px;
  margin-right:20px;
}
.center-block{
  width: 100px;
  /* display:inline-block; */
  padding:5px 15px;
  text-align:center;
  display:block;
  background: linear-gradient(90deg, #F4A223, #FF6000);
  border-radius: 20px;
  color:#FFFFFF;
  margin:0px 60px;
  margin-top:-15px;
}
.course-right-item{
  /* margin-top:150px; */
  width:350px;
  height:100px;
  margin-bottom:60px;
  /* padding-top:10px; */
  background-color:#F1F1F1;
}
.course-item-title-right{
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: #333333;
  padding-top:15px;
  margin:15px;
}
.course-item-content-right{
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
  line-height: 25px;
  margin:15px 0px 15px 15px;
}
.name-title{
    font-size: 40px;
    font-family: Arial;
    font-weight: bold;
    color: #333333;
}
.name{
  margin-top:50px;
  font-size: 22px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #999999;
}
</style>