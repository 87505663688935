import { createApp,onMounted } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import store from './store'
import router  from './router/index'
import api from "./untils/fetchUntils";
import MetaInfo from 'vue-meta-info'

const app = createApp(App);
app.use(router);
app.use(store);
// app.prototype.$api = api;
app.use(ElementPlus);
app.use(MetaInfo);
onMounted(()=>{
    document.dispatchEvent(new Event('custom-render-trigger'))
});
app.mount('#app');

app.config.globalProperties.$api=api;