<template>
  <div>
     <router-view></router-view>
  </div>
</template>

<script>
import Index from '@/views/index' 
export default {
  components: {
    Index
  },
  name: 'App',
  
  getScreen(){
    let clientWidth =document.documentElement.clientWidth || document.body.clientHeight;
    if(clientWidth < 1200){//当屏幕小于1200时，设置Vuex里的数据为true(这段代码的意思适合移动端，检测屏幕为手机可以隐藏掉一些东西。  )
        this.$store.commit("SET_PC",true)
    }else{//反之，设置Vuex里的数据为false
        this.$store.commit("SET_PC",false)
    }
  },
  setMobile(){
      //监听屏幕
      addEventListener('resize',()=>{
        this.getScreen();
      })
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
