<template>
    <div class = "heads">
        <div style="margin:10px auto;width:80%;height:80px;">
            <div class="point" @click="onlineService">
                <el-image class="img" :src="require('../../../assets/porun/service.png')" fit="cover"></el-image>
                <div class="content">在线客服</div>
            </div>
            <div class="line"></div>
        </div>
        <div style="margin:0 auto;width:80%;height:80px;" v-if="data.show">
            <div class="point" @click="navigate">
                <el-image class="img" :src="require('../../../assets/porun/problem.png')" fit="cover"></el-image>
                <div class="content">常见问题</div>
            </div>
            <div class="line"></div>
        </div>
        <div style="margin:0 auto;width:80%;height:80px;">
            <div class="point" @click="top">
                <el-image class="img" :src="require('../../../assets/porun/top.png')" fit="cover"></el-image>
                <div class="content">回到顶部</div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted } from 'vue'
import  {useRouter,useRoute} from "vue-router";
export default defineComponent({
    setup () {
      const router = useRouter();
      const route = useRoute();
      const data = ref({
          ruleForm:{},
          show:true,
          scroll:'',
          flag:false,
      });
      const navigate = () => {
          router.push('/problem');
      };
      const onlineService = () => {
          window.location.href='https://cqporun.qiyukf.com/client?k=1b612990d48c46b17d211f640b68c6ec&wp=1&robotShuntSwitch=1&robotId=22042&gid=79922'
      };
      const top = () =>{
          window.scrollTo(0,0);
      }
      onMounted(()=>{
        if(route.path=='/problem'){
            data.value.show = false;
        }
      })
      return {
        onlineService,
        navigate,
        top,
        data,
      }
    }
})
</script>

<style scoped>
.heads{
    width: 70px;
    position: fixed;
    top:50%;
    z-index:999;
    right:20px;
    background:linear-gradient(90deg, #F4A223, #FF6000);
    text-align: center;
    border-radius: 13px;
}
.point{
    padding:4px;
}
.point:hover{
    cursor: pointer;
    background:linear-gradient(90deg, #F4A223, #F4A223);
}
.header{
    position:fixed ;
    
}
.content{
    font-size: 10px;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
}
.img{
    width: 30px;
    height: auto;
    text-align:center
}
.line{
    width: 38px;
    height: 1px;
    background: #FFFFFF;
    border-radius: 1px;
    margin:10px auto;
}
</style>