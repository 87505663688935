<template>
    <div class="head">
        <div class="header">
        <div class="title">
          <el-image  class="icon" :src="require('../../assets/porun.png')" fit="fill" width="100%"></el-image>
            <p class="item" style="cursor: pointer;" v-for="(item,index) in data.titles" :key="index">
                <span  @click="navigate(item.tab)">{{item.name}}</span>
            </p>
        </div>
        <el-image  style="width: 100%; height: auto;position:absolute;z-index:-1;margin-top:-100px;" :src="require('../../assets/convenience/bg.png')" fit="cover"></el-image>
        </div>
        <div style="min-width:1200px;margin:0 auto">
          <el-button color="#FD8420" @click="toBottom" class ="experience-btn">立即体验 <div class="box"></div></el-button>
        </div>
        <div class="word" style="opacity: 0;">占个位</div>
        <div class="word">
            业务介绍
        </div>
        <div class="word-content">
            <p>
                一台自助云打印终端，打造迷你自助图文店，提供24H*7的自助文印服务，解决各种场景下的打印需求问题。
            </p>
        </div>
        <div class="word">
          产品应用
        </div>
        <div class = "word-content">
            <p>产品照片</p>
        </div>
        <div class="word" style="margin:40px 0px;display:flex;justify-content: space-around">
            <div>
                <el-image style="width:600px;height:400px" :src="require('../../assets/convenience/product2.jpg')" fit="fill" width="100%"></el-image>
                <div style="font-size: 23px;color: #666666;">
                    <p>智能打印机</p>
                </div> 
            </div>
             <div>
                <el-image style="width:600px;height:400px;" :src="require('../../assets/printShop/newheighbox.png')" fit="fill" width="100%"></el-image>
                <div style="font-size: 23px;color: #666666">
                    <p>流海云盒</p>
                </div> 
             </div>
        </div>
        
        <div class = "word-content">
            <p>操作视频</p>
        </div>

        <div style="width:100%; min-width:1200px;margin:0 auto;display:flex;">
          <div class="business-title" style="margin-top:100px;width:50%;min-width:600px;">
              <el-image style="width:15%;min-width:100px;;height:auto;margin-left:150px" :src="require('../../assets/convenience/business.png')" fit="fill" width="100%"></el-image>
              <span class = "business">
                  商户
              </span>
              <div class = "business-con">
                  体积轻小，加纸方便
                  <br/>循环加墨，控制成本
              </div>
              <el-image style="width:15%;min-width:100px;height:auto;margin-top:200px;margin-left:150px;" :src="require('../../assets/convenience/user.png')" fit="fill" width="100%"></el-image>
              <span class = "user" >
                  用户
              </span>
              <div class ="user-con">
                  扫码一键打印
                  <br/>手机操作取件
              </div>
          </div>
          <div class ="video">
              <video  style="width:400px;height:auto;margin-top:100px;" muted poster="../../assets/convenience/dropScenario.jpg" controls  autoplay>
                <source src="https://porunoss.oss-cn-beijing.aliyuncs.com/others/official_web/%E4%BE%BF%E5%88%A9%E6%89%93%E5%8D%B0.mp4" type="video/mp4">
                "浏览器版本太低，不支持本视频播放，请升级浏览器"。
              </video>
          </div>
        </div>
        <div style="clear:both"></div>


        <div class = "content">
            <div class="word">
              应用程序
              <div class ="down-load" style="opacity: 0;">
                app应用下载
              </div>
            </div>
            <div style="display:flex;justify-content: center;min-width:1200px;">
              <div style="width: 40%;min-withd:600px; height: 800px;">
                  <el-image  style="width: 15%;min-width:200px; height: auto;position: absolute;margin-top:200px;" lazy :src="require('../../assets/school/rectangle.png')" fit="fill"></el-image>
                  <el-image  style="width: 70%;min-width:336px; height: auto;" lazy :src="require('../../assets/school/newhighApp.png')" fit="fill"></el-image>
                  <el-image  style="width:12%;min-width:150px; height: auto;position: absolute;margin-top:100px;margin-left:-80px;" lazy :src="require('../../assets/school/orange-line.png')" fit="fill"></el-image>
              </div>
              <div class="school-title" style="font-size:30px;color:#E86D07;width:550px;white-space:normal;position:relative;">
                流海APP，微信小程序、支付宝小程序 QQ小程序、微信公众号、网站和邮箱
                <p class = "school-main">
                  <span class="blod">文档打印<br/></span>
                  支持word、pdf、ppt、wps等文档格式A4纸
                  <br/><span class="blod">照片冲印</span>
                  <br/>支持6寸照片冲印，高清保真
                  <br/><span class="blod">智能证件照</span>
                  <br/>已有证件照拼版打印功能，无电子照可在线打印、支持蓝底、红底、白底证件照底色。支持1寸、2寸等所有证件照尺寸
                  <br/><span class="blod">复印</span> <br/>支持身份证与文件复印，A4页面排版，高清还原，支持彩色/黑白打印
                  <br/><span class="blod">论文服务 </span>
                  <br/>流海论文查重对接维普和paperdd数据库，一键上传，10分钟出结果。准确、高效、性价比高
                  <br/><span class = "btn">用户端</span>
                </p>
              </div>
            </div>
            
            
        </div>

        <div class = "content-right" style="margin-top:400px;">
            <p class="school-title-right" style="color:#384368;">
              流海联盟微信小程序
            </p>
            <p class = "school-main-right">
              <span class="blod-phone">终端数据<br/></span>
              <br/>实时监控终端状态，故障原因及报修等
              <br/><span class="blod-phone">经营管理</span>
              <br/>管理者可设置创建网点信息，包括位置、价格、功能等，可实时查询订单信息及经营数据
              <br/><span class="blod-phone">物资管理</span>
              <br/>所有耗材的申请、发货、入库、消耗、结余等可视化
              <br/><span class="blod-phone">N+1提现</span>
              <br/>管理员申请后，收益次日到账，自动打款
              <br/><span class="blod-phone">分区管理 </span>
              <br/>按类型划分学校、社区、文印店界面信息、数据清晰可查
              <br/><span class = "btn" style="background:#6E77BA;margin-top:20px;">商户端</span>
            </p>
            
        </div>
        <div  class = "content-right" style="float:right;margin-top:-720px;">
                <el-image  style="width: 300px; height: auto;position: absolute;margin-left:-200px;margin-top:150px;" lazy :src="require('../../assets/school/blue-line.png')" fit="cover"></el-image>
                <el-image  style="width: 300px; height: auto;position: absolute;margin-top:200px;margin-left:200px" lazy :src="require('../../assets/school/ellipse.png')" fit="cover"></el-image>
                <el-image  style="width: 500px; height: auto;" lazy :src="require('../../assets/school/newhighLeague.png')" fit="cover"></el-image>  
        </div>

        <div class="word">
            产品优势
        </div>
        <div class = "operational-standards">
            <div class = "nomal" v-for ="(item,index) in data.products" :key = "index">
                <el-image  style="width:130px;height:130px" :src="item.url" fit="fill"></el-image>
                <p class ="nomal-con">{{item.title}}</p>
                <br/> 
                <div class ="nomal-title">
                  {{item.content}}
                  <br/>{{item.content1}}
                </div>
            </div>
        </div>
        <div class="word">
            投放场景
        </div>  
        <div class="word-content">
            <p>
                学校、超市便利店、政务中心、医院、地铁站、文具店、相馆、夫妻小店、办公场景
            </p>
        </div>
 
        <el-carousel :interval="4000" type="card" height="350px" style="min-width:1200px;">
          <el-carousel-item v-for="(item,index) in data.printImgs" :key="index">
            <el-image  style="width:600px;height:450px" :src="item.url" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
        <foo></foo>
        <suspension></suspension>
    </div>
</template>

<script>
import { defineComponent,ref,onMounted} from 'vue'
import  {useRouter} from "vue-router";
import foo from '@/views/print/components/foo.vue'
import suspension from '@/views/print/components/suspension.vue'

export default defineComponent({
    components:{
      foo,
      suspension,
    },
    metaInfo: {
      title:'流海云印——便利打印',
      meta:[{
          name:'keyWords',
          content: '流海云印、高校打印、便利打印、智能文印店、云打印领跑者、打造迷你自助图文店、一台机器实现自助打印服务'
      },
      {
          name:'description',
          description: '流海云印集自助打印、云服务为一体，是信息时代“互联网+打印”的传统文印产业经营模式的创新。产品包括Web云平台、移动端（微信和APP）和云打印自助终端，打造了基于云打印服务为基础衍生出的大学校园o2o闭 环生态云系统。流海“互联网+教育”智慧校园，采用“分布式打印+智能云调度+多维度服务+综合物流+电子支付”为一体的校园流海云印云打印模式，服务于高校师生和学子。提供24h*7的便捷云印服务，同时丰富校园学习生活，提供同校学习资源和便捷学习服务。',
      }]
    },
    setup () {
       const router = useRouter(); 
        const data = ref({
        titles:[
          {
            name:'首页',
            tab:'/',
          },
          {
            name:'高校打印',
            tab:'/print',
          },
          {
            name:'便利打印',
            tab:'/convenience',
          },
          {
            name:'智能文印店',
            tab:'/intelligentPrintShop',
          },
          {
            name:'关于我们',
            tab:'/porun',
          }
        ],
        products:[
          {
            content:'微信小程序操作 ',
            content1:'无需注册下载',
            url:require('../../assets/convenience/1.png'),
            title:'使用门槛低',
          },
          {
            content:'文档、照片、复印、证件照',
            content1:'满足基本文印需求',
            url:require('../../assets/convenience/2.png'),
            title:'功能丰富',
          },
          {
            content:'成熟的运维管理体系',
            content1:'保障产品售后',
            url:require('../../assets/convenience/3.png'),
            title:'运维高效',
          },
          {
            content:'设备成本低',
            content1:'收益稳定可观',
            url:require('../../assets/convenience/4.png'),
            title:'回本周期短',
          },
          {
            content:'数据实时可查',
            content1:'不定期拉新引流活动',
            url:require('../../assets/convenience/5.png'),
            title:'配套服务',
          }
        ],
        printImgs:[
          {
            url:require('../../assets/convenience/product1.jpg'),
          },
          {
            url:require('../../assets/convenience/product2.jpg'),
          },
          {
            url:require('../../assets/convenience/dropScenario.jpg'),
          },
        ],
      })
      const navigate = (url) => {
        router.push(url)
      };
      
	    const left = ref(null);
      const toLeft = ()=>{
            console.log(left);
      }
      const toBottom = ()=>{
        window.scrollTo(0,document.body.scrollHeight);
      }
      onMounted(()=>{
            window.scrollTo(0,0);
        })
        return {
            data,
            navigate,
            toLeft,
            toBottom,
        }
    }
})
</script>

<style scoped>
.header{
  width: 100%;
  position: relative;
  min-width: 1200px;
  height: auto;
  margin: 0 auto;
}
@media screen and (max-width: 1600px) {
    .title {
        width: 1200px;
    }
}
.icon{
  position: relative;
  min-width: 200px;
  margin-right:200px;
  height: 50px;
  opacity: 0;
}
.title{
  margin:0 auto;
  width: 1200px;
  margin-top:25px;
  padding-bottom:25px;
  display: flex;
  flex-direction :row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.item{
  min-width: 100px;
  overflow: hidden;
  margin:10px auto;
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #FFFFFF;
}
.item:hover{
  font-size:21px;
  color: #FFFFFF;
  opacity:0.8;
}
.main{
  display: flex;
  justify-content: space-between;
  margin: 100px auto;
  overflow: hidden;
  width: 1200px;
}
.word{
  min-width:1200px;
  margin: 0 auto;
  font-size: 40px;
  text-align:center;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  margin-top:150px;
}

/deep/ .el-radio-button__inner{
  color:#FD8420;
  width: 333px;
}
.content{
  margin-top:250px;
  height:540px;
}
.school-title{
  min-width:30%;
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  white-space:nowrap;
  padding-top:100px;
}
.school-main{
  min-width: 40px;
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding-top:20px;
}
.school-title:after{
  content: "";
  display: block;
  clear:both
}
.blod{
  font-size: 20px;
  font-weight: bold;
}
.blod-phone{
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-top:30px;
}
.content-right{
  width: 50%;
  min-width: 600px;
  margin-top:100px;
}
.school-title-right{
  font-size: 35px;
  font-family: SimHei;
  font-weight: 400;
  color: #333333;
  width: 350px;
  margin:0 auto;
}
.school-main-right{
  font-size: 20px;
  font-family: SimHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  margin-top:20px;
  width: 500px;
  margin:0 auto;
}
.word-content{
    width: 1200px;
    font-size: 23px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
    line-height:50px;
    text-align: center;
}
.product-title{
    width: 880px;
    font-size: 22px;
    font-family: SimHei;
    color: #666666;
    margin:50px auto;
    text-align: center;
}
.down-load{
  font-size: 25px;
  font-weight: 400;
  color: #FD8420;
  }
.btn{
  background:#FD8420;
  width:200px;
  display:inline-block;
  height:60px;
  border-radius:30px;
  text-align:center;
  line-height:60px;
  color:#FFFFFF;
  margin-top:20px;
}
.materials{
  display:flex;
  justify-content: start;
  flex-wrap: wrap;
  height:800px;
  overflow:hidden;
}
.nomal{
  width: 300px;
  text-align:center;
  margin-left:70px;
}
.nomal-title{
  font-size: 22px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #666666;
  line-height: 36px;
}
.nomal-con{
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 35px;
  font-weight: normal;
  color: #333333;
  text-align:center;
  margin: 20px 20px 10px 20px;
}
.operational-standards{
  display:flex;
  justify-content:start;
  min-width: 1200px;
  margin-top:100px;
}


.experience-btn{
  margin-top:25%;
  margin-left:10%;
  width:250px;
  height:80px;
  color: white;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 40px;
  font-size: 30px;
  font-family: SimHei;
  font-weight: 400;
}
.box{
  border-style: solid;
  border-width: 15px 15px 15px 20px;
  border-color: transparent transparent transparent white;
  width: 0px;
  height: 0px;
  margin-left:10px;
}
.business-title:after,.business-title:before{
  content: "";
  display: block;
  clear: both;
  }
.business{
    min-width:80px;
    font-size: 40px;
    font-family: SimHei;
    font-weight: 400;
    color: #6E77BA;
}
.user{
    min-width: 80px;
    font-size: 40px;
    font-family: SimHei;
    font-weight: 400;
    color: #FD8420;
}
.business-con{  
    min-width:300px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 60px;
    margin-left:400px;
}
.user-con{
    min-width:300px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 60px;
    margin-left:400px;
}
.video{
    width: 50%;
}

.el-carousel__item{
  line-height: 450px;
  text-align: center;
}
</style>