<template>
    <div class = "head">
        <div class = "foo">
            <p class = "join">立即加盟，轻松创收！</p>
            <div class = "header">
                <el-image class = "img-shop" :src="require('../../../assets/terminal.png')" fit="cover"></el-image>
                <p class = "content">联系我们   <span>content us</span></p>
                <p class = "main">请您填写下面的信息，然后点击[立即提交]，我们将会很快给您回复</p>
                <div class = "form">
                     <el-form
                            ref="ruleForm"
                            :model="data.ruleForm"
                            :rules="rules"
                        >
                            <el-form-item prop="name" class = "left-item" >
                                <el-input v-model="data.ruleForm.name" placeholder="请输入名称/公司名称"></el-input>
                            </el-form-item>
                            <el-form-item prop="email" class = "right-item">
                                <el-input v-model="data.ruleForm.email" maxlength="100"  placeholder="请输入您的邮箱"></el-input>
                            </el-form-item>
                            <el-form-item prop="cellphone" maxlength="500"  show-word-limit class = "left-item">
                                <el-input v-model="data.ruleForm.cellphone" onkeyup="data.value=data.value.replace(/[^\d.]/g,'')" maxlength="20" placeholder="请输入您的电话号码"></el-input>
                            </el-form-item>
                            <el-form-item prop="school_name" class = "right-item">
                                <el-input v-model="data.ruleForm.school_name"  :placeholder="data.flag"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input type="textarea" style="width:600px" maxlength="500"  show-word-limit v-model="data.ruleForm.content" placeholder="可以写下您的留言(可不填)"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" class="btn" round @click="submitForm()" :disabled="data.show">立即提交</el-button>
                            </el-form-item>
                        </el-form>
                </div>
            </div>
            <company class = "company"></company>
            <div class="altar">
                <div class= "altar-content">
                    <span>COPYRIGHT © 2015-至今 重庆颇闰科技 </span>
                    <el-image style="position:relative;width:25px;height:25px;" :src="require('../../../assets/Public.png')" fit="fill"></el-image>
                    <span @click="toJump" style="cursor: pointer;">渝公网安备 50010702501096号</span>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref,getCurrentInstance,reactive,onMounted } from 'vue'
import company from '@/views/print/components/company_info.vue'
import  {useRouter,useRoute} from "vue-router"
import { ElMessage,ElMessageBox } from 'element-plus'
export default defineComponent({
    components:{
      company,
    },
    setup () {
      const data = ref({
          ruleForm:{
              name:'',
              email:'',
              cellphone:'',
              school_name:'',
              content:''
          },
          show:false,
          area_attribute:'学校',
          flag: '请输入想要加盟的高校',
      });
      const router = useRouter();
      const route = useRoute();
      onMounted(()=>{
        if(route.path=='/convenience'){
            data.value.area_attribute = '社区';
            data.value.flag = '请输入想要加盟的社区地址'
        }else if(route.path == '/intelligentPrintShop'){
            data.value.area_attribute = '文印店';
            data.value.flag = '请输入想要加盟的文印店地址'
        }else{
            data.value.area_attribute = '学校';
            data.value.flag = '请输入想要加盟的高校'
        }
      });
      const { proxy,appContext } = getCurrentInstance();
      const submitForm = () => {
            proxy.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // console.log(appContext)
                    // fetch请求，通过这种方式进行全局挂载
                    data.value.show = true;
                    appContext.config.globalProperties.$api.post("/official/website/contact/us",{
                        name:data.value.ruleForm.name,
                        email:data.value.ruleForm.email,
                        school_name:data.value.ruleForm.school_name,
                        content:data.value.ruleForm.content,
                        cellphone:data.value.ruleForm.cellphone,
                        area_attribute:data.value.area_attribute,
                        })
                    .then(res=>{
                        ElMessageBox({message:'提交成功，我们将很快为你们回复',type:'success'}, appContext);
                        });
                        data.value.show = false;
                } else {
                    ElMessageBox({message:'提交失败，请仔细核对填写资料',type:'error'}, appContext);
                    data.value.show = false;
                    return false
                }
            })
        }

        const resetForm = (formEl) => {
        if (!formEl) return
        formEl.resetFields()
        }

        const checkEmail = (rule, value, callback) => {
            let emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;            
            if (emailReg.test(value) && value != '') {
                return callback();                
            }else if(value ==''){
                callback(new Error("请输入您的邮箱"));
            }  else {
                callback(new Error("邮箱格式错误"));
            }
        };
        const checkPhone = (rule, value, callback) => {
            let emailReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;            
            if (emailReg.test(value) && value != '') {
                return callback();                
            }else if(value ==''){
                callback(new Error("请输入您的手机号"));
            } else {
                callback(new Error("手机号格式错误"));
            }
        };
        const checkSchoolName = (rule, value, callback) => {        
            if (value != '') {
                return callback();                
            } else {
                callback(new Error(data.value.flag));
            }
        };
        const rules = ({
            name: [
                {required: true, message: '请输入公司名称', trigger: 'blur'},
                {max:100, required: true, message: '长度不能超过100', trigger: 'blur'},
            ],
            email: [
                { required: true,validator:checkEmail,trigger: 'blur'},
            ],
            cellphone: [
                { required: true,validator:checkPhone,trigger: 'blur'},
            ],
            school_name: [
                { required: true,validator: checkSchoolName,trigger: 'blur'},
                {max:100, required: true, message: '长度不能超过100', trigger: 'blur'},
            ],
        })
      const navigate = () => {};
      const toJump = () =>{
          window.location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010702501097';
      };
      return {
        data,
        navigate,
        toJump,
        submitForm,
        rules,
        checkEmail,
        checkPhone,
        checkSchoolName
      }
    }
})
</script>

<style scoped>
.head{
    width: 100%;
    position: relative;
	min-width: 1200px;
    height: auto;
	margin: 0 auto;
}
.foo{
    margin-top:150px;
    height: 1470px;
    background: linear-gradient(90deg, #F4A223, #FF6000);
}
.join{
    padding-top:70px;
    text-align:center;
    font-size: 40px;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
}
.header{
    text-align:center;
    margin:50px auto;
    width: 1200px;
    height: 800px;
    background: #FFFFFF;
    box-shadow: 0px 3px 40px 0px rgba(194, 119, 6, 0.35);
    border-radius: 20px;
}
.content{
    padding-top:50px;
    font-size: 35px;
    font-family: SimHei;
    font-weight: bold;
    color: #333333;
}
.main{
    margin-top:40px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #999999;
    line-height: 30px;
}
.img-shop{
    width: 600px;
    height: 700px;
    float:left;
    margin:50px 0 0 -200px;
}
.right-item{
    width: 600px;
}
.left-item{
    width:600px;
}
@media screen and (max-width: 1600px) {
    .img-shop {
        width: 500px;
        height: 600px;
        margin:100px 0 0 -100px;
    }
    .header{
        width: 1000px;
    }
    /deep/ .el-textarea__inner{
        width: 400px;
    }
    .right-item{
        width: 500px;
    }
    .left-item{
        width:500px;
    }
}
.form{
    width:650px;
    height:400px;
    margin:50px 0 0 450px;
}
/deep/ .el-input__inner{
    height: 50px;
}
/deep/ .el-textarea__inner{
    height: 120px;
}
/deep/ .el-input__inner:focus{
    border-color:#F4A223;
}
/deep/ .el-textarea__inner:focus{
    border-color:#F4A223;
}
.btn{
    margin: 20px 0 0 150px;
    background-color:#FD8420;
    border-color:#FD8420;
    width: 300px;
    height: 40px;
    box-shadow: 0px 6px 13px 0px rgba(58, 9, 123, 0.35);
}
.btn:hover{
    background-color: #F4A223;
    border-color: #F4A223;
}
.btn:focus{
    background-color: #FD8420;
    border-color: #FD8420;
}
.company{
    margin-top:100px;
}
.altar{
    background: linear-gradient(90deg, #F4A223, #FF6000);
    font-size: 18px;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
}
.altar-content{
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>