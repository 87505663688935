import { createRouter, createWebHashHistory,createWebHistory  } from 'vue-router'
import  schoolIndex from '../views/print/index.vue'//高校打印
// import Home from '../views/Home.vue'
import Index from '../views/index.vue'//首页
import Convenience from '../views/print/convenience' //便利打印
import intelligentPrintShop from '../views/print/intelligentPrintShop'//智能文印店
import PoRun from '../views/print/porun'//关于颇闰
import Problem from '../views/problem/index' //常见问题

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path:'/print',
    name:'Print',
    component: schoolIndex
  },
  {
    path:'/convenience',
    name:'Convenience',
    component: Convenience
  },
  {
    path:'/intelligentPrintShop',
    name:'IntelligentPrintShop',
    component: intelligentPrintShop
  },
  {
    path:'/porun',
    name:'PoRun',
    component: PoRun
  },
  {
    path:'/problem',
    name:'Problem',
    component: Problem
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
